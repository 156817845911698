import CardCarousel from "../Components/CardCarousel";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Descreption from "../Components/Descreption";
import Navbar from "../Components/Navabar/Navbar";
import "../../App.css";
const Home = () => {
  return (
    <div className="flex md:flex-row flex-col justify-between xl:justify-evenly md:mx-10 ">
      <div className="md:w-1/3">
        <Descreption />
      </div>
      <div className="w-full">
        <CardCarousel />
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from "../../config";


const apiUrl = config.apiUrl

function EmailVerified() {
    const { id, token } = useParams();  // Get the id and token from the URL
    const [status, setStatus] = useState('');

    useEffect(() => {
        const verifyAccount = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/auth/email-verified/${id}/${token}`);
                const data = await response.text();  // Assuming the backend sends a text response
                setStatus(data);  // Set the status to the response text
            } catch (error) {
                console.error('Error verifying account:', error);
                setStatus('Error verifying account. Please try again later.');
            }
        };

        verifyAccount();
    }, [id, token]);

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded shadow-lg text-center">
                <h1 className="text-2xl font-bold mb-4">{status}</h1>
                {status === 'Account successfully verified.' && (
                    <>
                        <p className="text-gray-700">Votre compte est maintenant activé et prêt à l'emploi.</p>
                        <a href="/login" className="mt-6 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            Se connecter
                        </a>
                    </>
                )}
            </div>
        </div>
    );
}

export default EmailVerified;

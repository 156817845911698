import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';

const UserTableAdmin = ({ headers = [], data = [], editButton, deleteButton }) => {
  return (
    <div className="overflow-auto rounded-lg border border-gray-200 xl:mx-20 my-10">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="max-w-96 px-4 py-2 text-gray-700">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

UserTableAdmin.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  editButton: PropTypes.func.isRequired,
  deleteButton: PropTypes.func.isRequired
};

export default UserTableAdmin;

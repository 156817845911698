import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Modal, Button } from "antd";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";

const apiUrl = config.apiUrl;

const CardGrid = () => {
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [currentTitle, setCurrentTitle] = useState("");
  const { token, userData } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/publications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sortedData = response.data.publications
          .sort(
            (a, b) =>
              new Date(b.publication.date) - new Date(a.publication.date)
          ) // Sort from new to old
          .slice(0, 9); // Limit to 9 items
        setData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const viewFile = async (filePath, publicationId = null, fileName = null) => {
    try {
      // Log the file view to the backend if publicationId and fileName are provided
      if (publicationId && fileName) {
        await axios.post(
          `${apiUrl}/log-file-view`,
          {
            publicationId,
            userId: userData.id,
            fileName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      // Fetch and open the file
      const url = `${apiUrl}/files/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };
  const showModal = (files, title) => {
    setCurrentFiles(files);
    setCurrentTitle(title);
    setModalVisible(true);
  };

  const handleDownloadClick = (fileUrl) => {
    const link = document.createElement("a");
    link.href = `${apiUrl}/${fileUrl}`;
    link.download = fileUrl.split("/").pop();
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  if (data.length === 0) {
    return (
      <p className="text-center mt-20 text-2xl">Aucune publication trouvée.</p>
    );
  }

  return (
    <>
      <div className="bg-gray-50 my-10 p-10 rounded-lg">
        <div className="flex lg:flex-row flex-col items-center justify-between p-5">
          <div className="text-gray-800 text-3xl font-semibold">
            Publication
          </div>
          <div className="flex flex-row items-center">
            <Link
              to="/Publication_Resultat?view=publication"
              className="text-gray-800 underline lg:mt-2"
            >
              Voir Plus
            </Link>
            <IoIosArrowForward className="text-blue-500 text-center lg:mt-3 mt-1" />
          </div>
        </div>
        <div className="w-full grid  grid-cols-1 lg:grid-cols-2 mx-auto gap-5 md:mx-5 justify-items-center align-items-center">
          {data.map((item, index) => (
            <div
              key={index}
              className="p-4 bg-[#d3fcff] w-full rounded-lg shadow-md"
            >
              <h2 className="text-xl font-bold underline">
                {item.publication.PublicationName}
              </h2>
              <p>Type: {item.publication.type}</p>
              <p>
                {item.publication.type === "offre" && (
                  <>
                    {"Date d'expiration: "}
                    {moment(item.publication.expirationDate).format(
                      "DD/MM/YYYY"
                    )}
                  </>
                )}
              </p>
              <div className="flex flex-row items-center justify-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (item.publication.type === "offre" && item.fichiers) {
                      if (token) {
                        showModal(
                          [
                            {
                              url: item.fichiers.avis,
                              name: "Avis",
                              id: item.publication._id,
                            },
                            {
                              url: item.fichiers.reglement,
                              name: "Reglement",
                              id: item.publication._id,
                            },
                            {
                              url: item.fichiers.CPS,
                              name: "CPS",
                              id: item.publication._id,
                            },
                          ],
                          item.publication.PublicationName
                        );
                      } else {
                        navigate("/login");
                      }
                    } else if (
                      item.publication.type === "programme" &&
                      item.fichiers &&
                      item.fichiers.programmeFichier
                    ) {
                      viewFile(item.fichiers.programmeFichier, null, null);
                    }
                  }}
                  className="text-center text-white text-sm border-[#174666] border-2 bg-[#174666] hover:text-[#174666] hover:bg-white p-1 rounded-lg transition-all duration-500"
                >
                  Telecharger
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title={`Les fichiers de ${currentTitle}`}
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        className="p-4"
      >
        <div className="space-y-2">
          {currentFiles.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-gray-100 p-2 rounded-md shadow-sm"
            >
              <span className="text-gray-800 font-semibold">{file.name}</span>
              <Button
                type="link"
                className="text-blue-500"
                onClick={
                  () => viewFile(file.url, file.id, file.name) // Pass publicationId and fileName
                }
              >
                Télécharger
              </Button>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default CardGrid;

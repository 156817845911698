import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Modal, Button, DatePicker, Alert, Pagination, Switch } from "antd";
import Swal from "sweetalert2";
import { useAuth } from "../../AuthUser/context/AuthContent";
import { IoAddCircleOutline } from "react-icons/io5";
import Search from "../Components/Search";
import ModalAdd from "../Components/ModalAdd";
import Table from "../Components/Table";
import config from "../../config";

const apiUrl = config.apiUrl;

const PublicationTable = () => {
  const [publications, setPublications] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { token } = useAuth(); // Obtenez le token du contexte d'authentification
  const [currentPublication, setCurrentPublication] = useState(null);
  const [actif, setActif] = useState(false);
  const [updatedData, setUpdatedData] = useState({
    PublicationName: "",
    description: "",
    type: "",
    expirationDate: "",
    files: {
      avis: null,
      reglement: null,
      CPS: null,
      programmeFichier: null,
      rectificationFichier: null,
    },
    actif,
  });
  const viewFile = async (filePath) => {
    try {
      const url = `${apiUrl}/files/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { userData } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    avis: null,
    reglement: null,
    CPS: null,
    programmeFichier: null,
    rectificationFichier: null,
    expirationDate: "",
    description: "",
    actif : actif ,
  });
  const [error, setError] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const openModal = () => setIsModalOpen(true);
  const  closeModal = () =>{
    setIsModalOpen(false);
    resetForm()
  }

  const resetForm = () => {
    setFormData({
      title: "",
      type: "",
      expirationDate: "",
      description: "",
      avis: "",
      reglement: "",
      CPS: "",
      programmeFichier: "",
      rectificationFichier:"",
      actif : false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({}); // Reset errors

    const newErrors = {};

    // Validate fields
    if (!formData.title)
      newErrors.title = "Veuillez entrer le titre de la publication.";
    if (!formData.type) newErrors.type = "Veuillez sélectionner un type.";
    if (formData.type === "offre") {
      if (!formData.avis) newErrors.avis = "Veuillez télécharger l'avis.";
      if (!formData.reglement)
        newErrors.reglement = "Veuillez télécharger le règlement.";
      if (!formData.CPS) newErrors.CPS = "Veuillez télécharger le CPS.";
    } else if (formData.type === "programme" && !formData.programmeFichier) {
      newErrors.programmeFichier =
        "Veuillez télécharger le fichier de programme.";
    } else if (formData.type === "rectification" && !formData.rectificationFichier) {
      newErrors.rectificationFichier =
        "Veuillez télécharger le fichier de rectification.";
    }
    if (!formData.expirationDate)
      newErrors.expirationDate = "Veuillez entrer une date d'expiration.";
    if (!formData.description)
      newErrors.description = "Veuillez entrer une description.";

    // Set errors if any
    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    // Proceed with the submission if no errors
    if (!userData || !userData.id) {
      setError({ user: "Utilisateur non authentifié." });
      return;
    }

    const payload = { ...formData, actif }; // Ensure `actif` is included here
  console.log("payloadddddddddd",payload);


    const { isConfirmed } = await Swal.fire({
      title: "Es-tu sûr?",
      text: "Voulez-vous ajouter cette publication ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, ajoutez-le!",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#1FACC7",
      cancelButtonColor: "#FF0000",
      iconColor: "#1FACC7",
      customClass: {
        popup: "w-80 max-w-4xl",
      },
    });

    if (!isConfirmed) {
      return; // User canceled the operation
    }

    const form = new FormData();
    form.append("PublicationName", formData.title);
    form.append("type", formData.type);
    form.append("userId", userData.id);
    form.append("expirationDate", formData.expirationDate);
    form.append("description", formData.description);
    form.append("actif", actif);

    if (formData.type === "offre") {
      form.append("avis", formData.avis);
      form.append("reglement", formData.reglement);
      form.append("CPS", formData.CPS);
    } else if (formData.type === "programme") {
      form.append("programmeFichier", formData.programmeFichier);
    } else if (formData.type === "rectification") {
      form.append("rectificationFichier", formData.rectificationFichier);
    }

    try {
      const response = await axios.post(`${apiUrl}/api/publications`, form, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response.data);
      console.log("form data", form)

      fetchPublications(currentPage);
      resetForm();
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Publication ajoutée",
        text: "La publication a été ajoutée avec succès",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
    } catch (error) {
      console.log("Error response data:", error.response.data); // Debug error response
      if (error.response && error.response.status === 400) {
        // Map the backend errors to the form fields
        const errorData = {
          title: error.response.data.message.includes("titre")
            ? error.response.data.message
            : null,
          type: error.response.data.message.includes("type")
            ? error.response.data.message
            : null,
          avis: error.response.data.message.includes("Avis")
            ? error.response.data.message
            : null,
          reglement: error.response.data.message.includes("Règlement")
            ? error.response.data.message
            : null,
          CPS: error.response.data.message.includes("CPS")
            ? error.response.data.message
            : null,
          programmeFichier: error.response.data.message.includes("Programme")
            ? error.response.data.message
            : null,
          rectificationFichier: error.response.data.message.includes("Rectification")
            ? error.response.data.message
            : null,
          expirationDate: error.response.data.message.includes("expiration")
            ? error.response.data.message
            : null,
          description: error.response.data.message.includes("description")
            ? error.response.data.message
            : null,
        };
        console.log("Mapped errors:", errorData); // Debug mapped errors
        setError(errorData);
      } else {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur s'est produite lors de l'ajout de la publication. Veuillez réessayer ultérieurement.",
          confirmButtonColor: "#1FACC7",
          cancelButtonColor: "#FF0000",
          customClass: {
            popup: "w-80 max-w-4xl",
          },
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const onChangeActif = (checked) => {
    console.log(`switch to ${checked}`);
    if(checked === true){
      setActif(true);
    } else {
      setActif(false);
    }
    console.log("first", actif);
  };

  const onChangeActifUpdate = (checked) => {
    setUpdatedData({
      ...updatedData,
      actif: checked ? true : false,
  });
  };

  const fetchPublications = async (page = 1, searchTerm = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/publications?page=${page}&limit=10&search=${encodeURIComponent(
          searchTerm
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedData = response.data.publications.map((item) => ({
        publication: item.publication,
        fichiers: item.fichiers,
      }));
      setPublications(fetchedData);
      setFilteredData(fetchedData);
      setTotalPages(response.data.totalPages); // Update total pages
      setCurrentPage(response.data.currentPage); // Update current page
    } catch (error) {
      console.error("Error fetching publications:", error);
    }
  };
  const handleSearch = (searchTerm) => {
    fetchPublications(currentPage, searchTerm);
  };

  useEffect(() => {
    fetchPublications(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: "Es-tu sûr?",
      text: "Cette action supprimera définitivement la publication.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprime-le !",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#1FACC7",
      cancelButtonColor: "#FF0000",
      iconColor: "#1FACC7",
      customClass: {
        popup: "w-80 max-w-4xl",
      },
    });

    if (!isConfirmed) {
      return; // User canceled the operation
    }

    try {
      await axios.delete(`${apiUrl}/api/publications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPublications((prevPublications) =>
        prevPublications.filter((pub) => pub.publication._id !== id)
      );
      setFilteredData((prevFilteredData) =>
        prevFilteredData.filter((pub) => pub.publication._id !== id)
      );
      Swal.fire({
        icon: "success",
        title: "Supprimé!",
        text: "La publication a été supprimée.",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de la suppression de la publication. Veuillez réessayer ultérieurement.",
      });
      console.error("Error deleting publication:", error);
    }
  };

  const showModal = (publication) => {
    setCurrentPublication(publication);
    const expirationDate = publication.publication.expirationDate
      ? new Date(publication.publication.expirationDate)
      : null;

    setUpdatedData({
      PublicationName: publication.publication.PublicationName,
      description: publication.publication.description,
      type: publication.publication.type, // Fix the field name
      expirationDate:
        expirationDate && !isNaN(expirationDate.getTime())
          ? moment(expirationDate).format("YYYY-MM-DD HH:mm")
          : "", // Default to an empty string if the date is invalid
      files: publication.fichiers,
      actif: publication.publication.actif,
    });
    console.log("updated data", updatedData);
    setIsModalVisible(true);
  };

  const handleOk = async () => {

    const { isConfirmed } = await Swal.fire({
      title: "Es-tu sûr?",
      text: "Souhaitez-vous mettre à jour cette publication ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, mettez-le à jour !",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#1FACC7",
      cancelButtonColor: "#FF0000",
      iconColor: "#1FACC7",
      customClass: {
        popup: "w-80 max-w-4xl",
      },
    });

    if (!isConfirmed) {
      return; // User canceled the operation
    }

    const formData = new FormData();
    formData.append("PublicationName", updatedData.PublicationName);
    formData.append("description", updatedData.description);
    formData.append("type", updatedData.type);
    formData.append("expirationDate", updatedData.expirationDate);
    formData.append("actif", updatedData.actif);

    console.log("updated data", updatedData);

    Object.entries(updatedData.files).forEach(([key, file]) => {
      if (file) formData.append(key, file);
    });

    console.log("form dataaaaaaaaaaaaaaaaa",formData)

    try {
      await axios.put(
        `${apiUrl}/api/publications/${currentPublication.publication._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Ajout du token dans les en-têtes
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Publication mise à jour",
        text: "La publication a été mise à jour avec succès.",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });

      setPublications((prevPublications) =>
        prevPublications.map((pub) =>
          pub.publication._id === currentPublication.publication._id
            ? { ...pub, publication: { ...pub.publication, ...updatedData } }
            : pub
        )
      );
      setFilteredData((prevFilteredData) =>
        prevFilteredData.map((pub) =>
          pub.publication._id === currentPublication.publication._id
            ? { ...pub, publication: { ...pub.publication, ...updatedData } }
            : pub
        )
      );
      setIsModalVisible(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de la mise à jour de la publication. Veuillez réessayer ultérieurement.",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
      console.error("Error updating publication:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      files: {
        ...prevData.files,
        [id]: files[0],
      },
    }));
  };

  const tableData = filteredData.map((item) => {
    const publication = item?.publication || {};
    const fichiers = item?.fichiers || {};

    return [
      publication.actif ? <div className="h-2 w-2 rounded-full bg-green-500"></div> : <div className="h-2 w-2 rounded-full bg-red-500"></div>,
      publication.PublicationName || "N/A",
      publication.description || "N/A",
      publication.type || "N/A",
      moment(publication.expirationDate).format("YYYY-MM-DD HH:mm") || "N/A",
      publication.type === "offre" && fichiers ? (
        <>
          {fichiers.avis && (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  viewFile(fichiers.avis);
                }}
                className="text-blue-600 hover:text-blue-800"
              >
                Télécharger Avis
              </a>
            </>
          )}
          <br />
          {fichiers.reglement && (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  viewFile(fichiers.reglement);
                }}
                className="text-blue-600 hover:text-blue-800"
              >
                Télécharger Reglement
              </a>
            </>
          )}
          <br />
          {fichiers.CPS && (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  viewFile(fichiers.CPS);
                }}
                className="text-blue-600 hover:text-blue-800"
              >
                Télécharger CPS
              </a>
            </>
          )}
        </>
      ) : publication.type === "programme" && fichiers.programmeFichier ? (
        <>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(fichiers.programmeFichier);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            Télécharger fichier de Programme
          </a>
        </>
      ) :
        publication.type === "rectification" && fichiers.rectificationFichier ? (
          <>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                viewFile(fichiers.rectificationFichier);
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              Télécharger fichier de rectification
            </a>
          </>
      ) : (
        "N/A"
      ),
      publication._id || "N/A",
    ];
  });

  return (
    <>
     <h2 className="text-2xl font-bold text-gray-700 text-center mt-10 underline">
        Gestion des Publications
      </h2>
      <div className="flex flex-col ">
        <div className="flex md:flex-row flex-col justify-end md:items-center items-end gap-x-5 gap-y-2 mr-5 mt-10">
          <div>
            {(userData?.role === "Admin" || userData?.role === "Manager") && (
              <button
                onClick={openModal}
                className="flex flex-row justify-center items-center gap-x-3 bg-[#1FACC7] p-2 text-white rounded-lg hover:bg-[#116c7e] transition-all duration-500"
              >
                <IoAddCircleOutline />
                Ajouter une publication
              </button>
            )}
          </div>
          {userData?.role !== "manager" && <Search onSearch={handleSearch} />}
        </div>

        <Table
          headers={[
            "Actif",
            "Publication Name",
            "Description",
            "Type",
            "Expiration Date",
            "Files",
          ]}
          data={tableData}
          editButton={(id) =>
            showModal(publications.find((pub) => pub.publication._id === id))
          }
          deleteButton={(id) => handleDelete(id)}
        />
      </div>
      <Pagination
        align="center"
        current={currentPage}
        total={totalPages * 10}
        responsive
        pageSize={10}
        showSizeChanger={false}
        onChange={handlePageChange}
        className="p-5"
      />
      <ModalAdd
        isOpen={isModalOpen}
        closeModal={closeModal}
        onSubmit={handleSubmit}
        buttonName="Ajouter"
      >
        <h1 className="font-semibold flex justify-center mb-5 text-2xl">
          Ajouter une publication
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between flex-row my-3">
            <div className="flex flex-col gap-y-4 mr-2 w-1/2">
              <label className="text-black font-semibold">
                Titre de la publication :
              </label>
              <input
                type="text"
                name="title"
                className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                placeholder="Titre de la publication :"
                value={formData.title}
                onChange={handleChange}
                required
              />
              {error && error.title && (
                <span className="text-red-500">{error.title}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-4 w-1/2">
              <label className="text-black font-semibold">Type :</label>
              <select
                name="type"
                className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez un type</option>
                <option value="offre">Offre</option>
                <option value="programme">Programme</option>
                <option value="rectification">Rectification</option>
              </select>
              {error && error.type && (
                <span className="text-red-500">{error.type}</span>
              )}
            </div>
          </div>

          {formData.type === "offre" && (
            <>
              <div className="flex justify-between flex-row my-3">
                <div className="flex flex-col mr-2 gap-y-4 w-1/2">
                  <label className="text-black font-semibold">Avis :</label>
                  <input
                    type="file"
                    name="avis"
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                    onChange={handleChange}
                    required
                    accept="application/pdf"
                  />
                  {error && error.avis && (
                    <span className="text-red-500">{error.avis}</span>
                  )}
                </div>
                <div className="flex flex-col gap-y-4 w-1/2">
                  <label className="text-black font-semibold">
                    Règlement :
                  </label>
                  <input
                    type="file"
                    name="reglement"
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                    onChange={handleChange}
                    required
                    accept="application/pdf"
                  />
                  {error && error.reglement && (
                    <span className="text-red-500">{error.reglement}</span>
                  )}
                </div>
              </div>
              <div className="flex justify-between flex-row my-3">
                <div className="flex flex-col mr-2 gap-y-4 w-1/2">
                  <label className="text-black font-semibold">CPS :</label>
                  <input
                    type="file"
                    name="CPS"
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                    onChange={handleChange}
                    required
                    accept="application/pdf"
                  />
                  {error && error.CPS && (
                    <span className="text-red-500">{error.CPS}</span>
                  )}
                </div>
                <div className="flex flex-col gap-y-4 w-1/2">
                  <label className="text-black font-semibold ">
                    Date d'expiration :
                  </label>
                  <input
                    type="datetime-local"
                    name="expirationDate"
                    min={new Date().toJSON().slice(0, 10)}
                    className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                    value={formData.expirationDate}
                    onChange={handleChange}
                    required
                  />
                  {error && error.expirationDate && (
                    <span className="text-red-500">{error.expirationDate}</span>
                  )}
                </div>
              </div>
            </>
          )}

          {formData.type === "programme" && (
            <>
              <div className="flex justify-between flex-row my-3">
                <div className="flex flex-col mr-2 gap-y-4 w-1/2">
                  <label className="text-black font-semibold">
                    Programme Fichier :
                  </label>
                  <input
                    type="file"
                    name="programmeFichier"
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                    onChange={handleChange}
                    required
                    accept="application/pdf"
                  />
                  {error && error.programmeFichier && (
                    <span className="text-red-500">
                      {error.programmeFichier}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-y-4 w-1/2">
                  <label className="text-black font-semibold">
                    Date d'expiration :
                  </label>
                  <input
                    type="datetime-local"
                    name="expirationDate"
                    min={new Date().toJSON().slice(0, 10)}
                    className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                    value={formData.expirationDate}
                    onChange={handleChange}
                    required
                  />
                  {error && error.expirationDate && (
                    <span className="text-red-500">{error.expirationDate}</span>
                  )}
                </div>
              </div>
            </>
          )}

          {formData.type === "rectification" && (
            <>
              <div className="flex justify-between flex-row my-3">
                <div className="flex flex-col mr-2 gap-y-4 w-1/2">
                  <label className="text-black font-semibold">
                  Rectification Fichier :
                  </label>
                  <input
                    type="file"
                    name="rectificationFichier"
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                    onChange={handleChange}
                    required
                    accept="application/pdf"
                  />
                  {error && error.rectificationFichier && (
                    <span className="text-red-500">
                      {error.rectificationFichier}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-y-4 w-1/2">
                  <label className="text-black font-semibold">
                    Date d'expiration :
                  </label>
                  <input
                    type="datetime-local"
                    name="expirationDate"
                    min={new Date().toJSON().slice(0, 10)}
                    className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                    value={formData.expirationDate}
                    onChange={handleChange}
                    required
                  />
                  {error && error.expirationDate && (
                    <span className="text-red-500">{error.expirationDate}</span>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex flex-col mr-2 gap-y-4 justify-center items-center">
            <label className="text-black font-semibold">Description</label>
            <textarea
              type="text"
              name="description"
              className="resize-none text-black text-sm font-light rounded-lg bg-gray-100 w-96 h-24 pl-3"
              value={formData.description}
              onChange={handleChange}
              required
            />
            {error && error.description && (
              <span className="text-red-500">{error.description}</span>
            )}
          </div>

          <div className="flex flex-row mr-2 gap-x-4 mt-5 items-center">
            <label className="text-black font-semibold">Actif</label>
            <Switch  onChange={onChangeActif} />
          </div>

          <button type="submit" className="hidden"></button>
        </form>
      </ModalAdd>

      {currentPublication && (
        <Modal
          title="Modifier la publication"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Sauvegarder"
          cancelText="Annuler"
        >
          <div className="flex justify-between flex-row my-3">
            <div className="flex flex-col gap-y-4 mr-2 w-1/2">
              <label htmlFor="PublicationName">Publication Name:</label>
              <input
                type="text"
                id="PublicationName"
                value={updatedData.PublicationName}
                className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                onChange={(e) =>
                  setUpdatedData({
                    ...updatedData,
                    PublicationName: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col gap-y-4 mr-2 w-1/2">
              <label htmlFor="type">Type:</label>
              <select
                id="type"
                value={updatedData.type}
                className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                onChange={(e) =>
                  setUpdatedData({ ...updatedData, type: e.target.value })
                }
              >
                <option value="">Select Type</option>
                <option value="offre">Offre</option>
                <option value="programme">Programme</option>
              </select>
            </div>
          </div>
          <div className="flex justify-between flex-row my-3">
            <div className="flex flex-col gap-y-4 mr-2 w-1/2">
              <label htmlFor="expirationDate">Expiration Date:</label>
              <input
                type="datetime-local"
                id="expirationDate"
                value={updatedData.expirationDate}
                className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
                onChange={(e) =>
                  setUpdatedData({
                    ...updatedData,
                    expirationDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col gap-y-4 mr-2 w-1/2">
              {updatedData.type === "programme" && (
                <>
                  <label htmlFor="programmeFichier">Programme File:</label>
                  <input
                    type="file"
                    id="programmeFichier"
                    required
                    onChange={handleFileChange}
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex justify-between flex-row my-3">
            <div className="flex flex-col gap-y-4 mr-2 w-1/2">
              {updatedData.type === "offre" && (
                <>
                  <label htmlFor="avis">Avis:</label>
                  <input
                    type="file"
                    id="avis"
                    required
                    onChange={handleFileChange}
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                  />
                  <label htmlFor="reglement">Reglement:</label>
                  <input
                    type="file"
                    id="reglement"
                    requireds
                    onChange={handleFileChange}
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                  />
                  <label htmlFor="CPS">CPS:</label>
                  <input
                    type="file"
                    id="CPS"
                    required
                    onChange={handleFileChange}
                    className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col mr-2 gap-y-4 justify-center items-center">
            <label className="text-black font-semibold">Description</label>
            <textarea
              type="text"
              name="description"
              className="resize-none text-black text-sm font-light rounded-lg bg-gray-100 w-96 h-24 pl-3"
              value={updatedData.description}
              onChange={(e) =>
                setUpdatedData({ ...updatedData, description: e.target.value })
              }
              required
            />
          </div>
          <div className="flex flex-row mr-2 gap-x-4 mt-5 items-center">
            <label className="text-black font-semibold">Actif</label>
            <Switch value={updatedData.actif}  onChange={onChangeActifUpdate} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default PublicationTable;

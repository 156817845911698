import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";
import axios from "axios";
import moment from "moment";
import Table from "../Components/Table";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import { FaList } from "react-icons/fa";

const DepotAdminList = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [publications, setPublications] = useState([]);
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const apiUrl = config.apiUrl;

  const fetchPublications = async (page = 1, searchTerm = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/publications?page=${page}&limit=10&search=${encodeURIComponent(
          searchTerm
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedData = response.data.publications.map((item) => ({
        publication: item.publication,
        fichiers: item.fichiers,
      }));
      setPublications(fetchedData);
      setFilteredData(fetchedData);
      setTotalPages(response.data.totalPages); // Update total pages
      setCurrentPage(response.data.currentPage); // Update current page
    } catch (error) {
      console.error("Error fetching publications:", error);
    }
  };

  useEffect(() => {
    fetchPublications(currentPage);
  }, [currentPage]);

  const handleRowClick = (publicationId) => {
    console.log("Publication ID:", publicationId); // Debugging line
    navigate(`/SoumissionAdmin/${publicationId}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const tableData = filteredData.map((item) => {
    const publication = item?.publication || {};

    return [
      publication.actif ? (
        <div className="h-2 w-2 rounded-full bg-green-500"></div>
      ) : (
        <div className="h-2 w-2 rounded-full bg-red-500"></div>
      ),
      publication.PublicationName || "N/A",
      publication.type || "N/A",
      moment(publication.expirationDate).format("YYYY-MM-DD HH:mm") || "N/A",
      <div className="flex justify-center">
        <FaList
          className="text-blue-600 hover:text-blue-800 cursor-pointer"
          onClick={() => handleRowClick(publication._id)}
        />
      </div>,
      publication._id,
    ];
  });

  return (
    <>
     <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 mt-10 underline">
     Les dépôts des utilisateurs Par Publication
      </h2>
      <Table
        headers={[
          "Actif",
          "Publication Name",
          "Type",
          "Expiration Date",
          "Action",
        ]}
        data={tableData}
      />
      <Pagination
        align="center"
        current={currentPage}
        total={totalPages * 10}
        responsive
        pageSize={10}
        showSizeChanger={false}
        onChange={handlePageChange}
        className="p-5"
      />
    </>
  );
};

export default DepotAdminList;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, Steps, message } from 'antd';
import axios from 'axios';
import { useAuth } from '../../AuthUser/context/AuthContent';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../config';


const apiUrl = config.apiUrl

const { Step } = Steps;

const steps = [
  { title: 'Étape 1', description: 'Dossier administratif' },
  { title: 'Étape 2', description: 'Dossier technique' },
];

const MAX_TOTAL_FILE_SIZE = 8 * 1024 * 1024; // 8 MB (total size for all documents)
const MAX_SINGLE_FILE_SIZE = 2 * 1024 * 1024; // 2 MB (for autreDocument1 and autreDocument2)
const ALLOWED_FILE_TYPE = "application/pdf";

const personneFields = [
  { 
    name: 'cinCopie', 
    label: 'Copie de la CIN',
    required: true 
  },
  { 
    name: 'diplomMoinsUnAn', 
    label: `Copie certifiée conforme à l'original du diplôme (pour les diplômés moins d'un an)`,
    required: false 
  },
  { 
    name: 'scholarshipCertificat', 
    label: 'Certificat de scolarité (pour les étudiants)',
    required: false 
  }
];



const validateFile = (file, fieldName) => {
  if (file && file[0]) {
    if (file[0].type !== ALLOWED_FILE_TYPE) {
      return 'Le fichier doit être au format PDF.';
    }
    if ((fieldName === 'autreDocument1' || fieldName === 'autreDocument2') && file[0].size > MAX_SINGLE_FILE_SIZE) {
      return 'Le fichier ne doit pas dépasser 2 Mo.';
    }
  }
  return true;
};

const DossierForm = () => {
  const { userData , token } = useAuth();
  const location = useLocation();
  const { publicationId } = location.state;
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [current, setCurrent] = useState(0);
  const { register, handleSubmit, formState: { errors }, trigger, watch } = useForm({
    mode: 'onChange',
  });
  const [formType, setFormType] = useState(userData?.type?.toLowerCase() === 'societe' ? 'societe' : 'personne');
  const [fileErrors, setFileErrors] = useState({});
const [formData, setFormData] = useState({});
const [formValues, setFormValues] = useState({
  files: {},
  inputs: {}
});


  const validateInputs = (data) => {
    const filteredData = {};
    for (const key in data) {
      if (typeof data[key] === 'string') {
        filteredData[key] = data[key].replace(/<[^>]*>/g, ''); // Prevent HTML/JS injection
      } else {
        filteredData[key] = data[key];
      }
    }
    return filteredData;
  };

  // log user Type from token

  if (userData && userData.type && 
    (userData.type.toLowerCase() === 'personne' || userData.type.toLowerCase() === 'societe')) {
  console.log("User Type logged as:", userData.type);
} else {
  console.log("userType is either undefined or does not match 'personne' or 'socite'.");
}

console.log("userData test:", userData);

  

  const onSubmit = async (data) => {
    const submitFormData = new FormData();
    const filteredData = validateInputs(data);
  
    if (formType === 'personne') {
      // Check for required cinCopie
      if (!formValues.files['cinCopie']) {
        message.error('La copie de la CIN est obligatoire');
        return;
      }
  
      // Check if at least one of diplomMoinsUnAn or scholarshipCertificat is provided
      const hasOptionalFile = formValues.files['diplomMoinsUnAn'] || formValues.files['scholarshipCertificat'];
      if (!hasOptionalFile) {
        message.error('Vous devez fournir soit le diplôme, soit le certificat de scolarité');
        return;
      }
    }
    // Append regular form fields
    for (const key in filteredData) {
      if (filteredData[key] !== undefined && filteredData[key] !== null) {
        submitFormData.append(key, filteredData[key]);
      }
    }
  
    // Append stored files from formValues
    Object.keys(formValues.files).forEach(key => {
      submitFormData.append(key, formValues.files[key]);
    });
  
    // Calculate total file size
    const totalSize = Object.values(formValues.files)
      .reduce((sum, file) => sum + file.size, 0);
  
    if (totalSize > MAX_TOTAL_FILE_SIZE) {
      message.error('La taille totale des fichiers ne doit pas dépasser 8 Mo.');
      return;
    }
  
    // Append user and publication IDs
    submitFormData.append('userId', userData.id);
    submitFormData.append('publicationId', publicationId);
  
    try {
      const endpoint = formType === 'personne' 
        ? `${apiUrl}/api/dossiers/personne_physique`
        : `${apiUrl}/api/dossiers`;
  
      const response = await axios.post(endpoint, submitFormData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        message.success('Le dossier a été soumis avec succès!');
        navigate("/Soumission");
      } else {
        message.error('Échec de la soumission du dossier.');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        message.error(`${error.response.data?.message || 'Vous avez déjà soumis un dossier pour cette publication.'}`);
      } else if (error.request) {
        console.error('Request:', error.request);
        message.error('Pas de réponse du serveur. Vérifiez votre connexion.');
      } else {
        console.error('Error message:', error.message);
        message.error('Erreur lors de la préparation de la requête.');
      }
    }
  };
  

  // const handleNext = async () => {
  //   const isStepValid = await trigger();
  //   if (isStepValid) {
  //     setCurrent(current + 1);
  //   }
  // };

console.log('');
  // const handleNext = async () => {
  //   const isStepValid = await trigger();
  //   const hasFileErrors = Object.keys(fileErrors).length > 0;
    
  //   // Check if there are any file errors
  //   if (hasFileErrors) {
  //     message.error('Veuillez corriger les erreurs de fichiers avant de continuer');
  //     return;
  //   }
  
  //   // Check if required files are uploaded for current step
  //   const requiredFiles = current === 0 
  //     ? ['declarationHonneur', 'declarationHonneur2', 'cinCopie', 'quitusFiscal', 'cnssMoinsUnAn', 'delegationPouvoir', 'registreCommerce', 'statutSociete', 'attestationCautionnement', 'agrementCCM']
  //     : ['moyensTechniques', 'moyensHumains', 'references'];
  
  //   const missingFiles = requiredFiles.filter(fileName => !formValues.files[fileName]);
    
  //   if (missingFiles.length > 0) {
  //     message.error('Veuillez télécharger tous les fichiers requis avant de continuer');
  //     return;
  //   }
  
  //   if (isStepValid) {
  //     setCurrent(current + 1);
  //   }
  // };

  const handleNext = async () => {
    const isStepValid = await trigger();
    const hasFileErrors = Object.keys(fileErrors).length > 0;
    
    // Check if there are any file errors
    if (hasFileErrors) {
      message.error('Veuillez corriger les erreurs de fichiers avant de continuer');
      return;
    }
  
    // Check if required files are uploaded for current step
    const requiredFiles = current === 0 
      ? ['declarationHonneur', 'declarationHonneur2', 'quitusFiscal', 'cnssMoinsUnAn', 'delegationPouvoir', 'registreCommerce', 'statutSociete', 'attestationCautionnement', 'agrementCCM']
      : ['moyensTechniques', 'moyensHumains', 'references'];
  
    const missingFiles = requiredFiles.filter(fileName => !formValues.files[fileName]);
    
    if (missingFiles.length > 0) {
      message.error('Veuillez télécharger tous les fichiers requis avant de continuer');
      return;
    }
  
    if (isStepValid) {
      setCurrent(current + 1);
    }
  };
  
  
  // const handleFileChange = (e, fieldName) => {
  //   const file = e.target.files[0];
  //   const newFormData = { ...formData };
  //   const newFileErrors = { ...fileErrors };
  
  //   if (file) {
  //     if (file.size > MAX_SINGLE_FILE_SIZE) {
  //       newFileErrors[fieldName] = 'Le fichier doit être inférieur à 2 Mo';
  //       e.target.value = '';
  //       delete newFormData[fieldName];
  //     } else if (file.type !== ALLOWED_FILE_TYPE) {
  //       newFileErrors[fieldName] = 'Le fichier doit être au format PDF';
  //       e.target.value = '';
  //       delete newFormData[fieldName];
  //     } else {
  //       delete newFileErrors[fieldName];
  //       newFormData[fieldName] = file;
  
  //       // Calculate total size
  //       const totalSize = Object.values(newFormData)
  //         .filter(f => f instanceof File)
  //         .reduce((sum, f) => sum + f.size, 0);
  
  //       if (totalSize > MAX_TOTAL_FILE_SIZE) {
  //         newFileErrors[fieldName] = 'La taille totale des fichiers dépasse 8 Mo';
  //         e.target.value = '';
  //         delete newFormData[fieldName];
  //       }
  //     }
  //   }
  
  //   setFileErrors(newFileErrors);
  //   setFormData(newFormData);
  // };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    const newFileErrors = { ...fileErrors };
    const newFormValues = { ...formValues };
  
    if (file) {
      if (file.size > MAX_SINGLE_FILE_SIZE) {
        newFileErrors[fieldName] = 'Le fichier doit être inférieur à 2 Mo';
        e.target.value = '';
        delete newFormValues.files[fieldName];
      } else if (file.type !== ALLOWED_FILE_TYPE) {
        newFileErrors[fieldName] = 'Le fichier doit être au format PDF';
        e.target.value = '';
        delete newFormValues.files[fieldName];
      } else {
        delete newFileErrors[fieldName];
        newFormValues.files[fieldName] = file;
  
        // Calculate total size
        const totalSize = Object.values(newFormValues.files)
          .reduce((sum, f) => sum + f.size, 0);
  
        if (totalSize > MAX_TOTAL_FILE_SIZE) {
          newFileErrors[fieldName] = 'La taille totale des fichiers dépasse 8 Mo';
          e.target.value = '';
          delete newFormValues.files[fieldName];
        }
      }
    }
  
    setFileErrors(newFileErrors);
    setFormValues(newFormValues);
  };
  
  const handlePrev = () => {
    setCurrent(current - 1);
  };

  // const handleFormTypeChange = (type) => {
  //   setFormType(type);
  // };
  
  
  return (
    <div className="max-w-4xl mx-auto p-6">
      <Steps current={current}>
        {steps.map((step, index) => (
          <Step key={index} title={step.title} description={step.description} />
        ))}
      </Steps>



{formType === 'societe' ? (
<div>
<Alert
  message={<b>Alerte d'Information</b>}
  className='mt-6'
  description={
<>
  Veuillez noter que les fichiers doivent être soumis au format PDF et ne doivent pas excéder <b>2 Mo</b> par fichier,
  avec une taille totale maximale de <b>8 Mo</b> pour l'ensemble des fichiers.
  Il est également impératif de télécharger le <b>règlement de consultation</b> ainsi que le <b>Cahier des Prescriptions Spéciales (CPS)</b>,
  de les parapher, les signer, puis de les soumettre physiquement dans une <b>enveloppe scellée</b>.
</>

  }
  type="info"
  showIcon
/>
      <form onSubmit={handleSubmit(onSubmit)}>
        {current === 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            {/* Administrative dossier*/}
            {[
              { name: 'nomPrenom', label: 'Nom et Prénom Responsable ', placeholder: "Nom et Prénom Responsable" },
              { name: 'identifiantFiscal', label: 'Identifiant fiscal', placeholder: "Identifiant fiscal" },
              { name: 'cnss', label: 'CNSS', placeholder: "CNSS" },
              { name: 'numeroRegistreCommerce', label: 'Numéro de commerce', placeholder: "Numéro de commerce" }
            ].map((field) => (
              <div key={field.name}>
                <label className="block text-sm font-medium">{field.label} <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span>&nbsp;</label>
                <input
                  className={`w-full p-3 sm:px-3 lg:px-4 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 ${errors[field.name] ? 'border-red-500' : ''}`}
                  placeholder={field.placeholder}
                  {...register(field.name, { required: 'Ce champ est requis.' })}
                />
                {errors[field.name] && <p className="text-red-500">{errors[field.name].message}</p>}
              </div>
            ))}

            {/* File upload fields for administrative dossier */}
            {[
              { name: 'declarationHonneur', label: 'Déclaration sur l\'honneur 1' },
              { name: 'declarationHonneur2', label: 'Déclaration sur l\'honneur 2' },
              { name: 'quitusFiscal', label: 'Quitus fiscal (Moins d\'un an)' },
              { name: 'cnssMoinsUnAn', label: 'CNSS (Moins d\'un an)' },
              { name: 'delegationPouvoir', label: 'Delegation de pouvoir' },
              { name: 'registreCommerce', label: 'Numéro de registre de commerce' },
              { name: 'statutSociete', label: 'Status de la société' },
              { name: 'attestationCautionnement', label: 'Attestation de cautionnement' },
              { name: 'agrementCCM', label: 'Agrément CCM (le cas de fiction)' }
            ].map((field) => (
<div key={field.name} className=''>
  <label className="block text-sm font-medium">{field.label} <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span></label>
  <input
    className={`w-full p-1 sm:px-1 lg:px-1 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0 ${fileErrors[field.name] ? 'border-red-500' : ''}`}
    type="file"
    onChange={(e) => handleFileChange(e, field.name)}
    key={`${field.name}-${formValues.files[field.name]?.name || 'empty'}`}
  />
  {formValues.files[field.name] && (
    <p className="text-green-600 text-sm mt-1">
      Fichier sélectionné: {formValues.files[field.name].name}
    </p>
  )}
  {fileErrors[field.name] && (
    <p className="text-red-500 text-sm mt-1">{fileErrors[field.name]}</p>
  )}
</div>

            ))}
          </div>
        )}

        {current === 1 && (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
  {[
    { name: 'moyensTechniques', label: 'Moyens Techniques' },
    { name: 'moyensHumains', label: 'Moyens Humains' },
    { name: 'references', label: 'Référence' },
    // { name: 'autreDocument1', label: 'Autre Document 1' },
    // { name: 'autreDocument2', label: 'Autre Document 2' }
  ].map((field) => (
    // <div key={field.name}>
    //   <label className="block text-sm font-medium">
    //     {field.label}
    //     {field.name !== 'autreDocument1' && field.name !== 'autreDocument2' && (
    //       <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span>
    //     )}
    //   </label>
    //   <input
    //     className={`w-full p-1 sm:px-1 lg:px-1 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0 ${errors[field.name] ? 'border-red-500' : ''}`}
    //     type="file"
    //     {...register(field.name, {
    //       required: field.name !== 'autreDocument1' && field.name !== 'autreDocument2' ? 'Fichier PDF requis.' : false,
    //       validate: (value) => validateFile(value, field.name)
    //     })}
    //   />
    //   {errors[field.name] && <p className="text-red-500">{errors[field.name].message}</p>}
    // </div>
    <div key={field.name} className=''>
  <label className="block text-sm font-medium">{field.label} <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span></label>
  <input
    className={`w-full p-1 sm:px-1 lg:px-1 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0 ${fileErrors[field.name] ? 'border-red-500' : ''}`}
    type="file"
    onChange={(e) => handleFileChange(e, field.name)}
    key={`${field.name}-${formValues.files[field.name]?.name || 'empty'}`}
  />
  {formValues.files[field.name] && (
    <p className="text-green-600 text-sm mt-1">
      Fichier sélectionné: {formValues.files[field.name].name}
    </p>
  )}
  {fileErrors[field.name] && (
    <p className="text-red-500 text-sm mt-1">{fileErrors[field.name]}</p>
  )}
</div>
  ))}

</div>
)}
<div className="mt-9">
<p className="mt text-md text-gray-500 italic ">
  <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span>&nbsp; signifie que tous les champs sont obligatoires.
</p>
</div>
        <div className="flex justify-between mt-6">
          {current > 0 && (
            <div className=" w-full ">
            <Button onClick={handlePrev} className=' w-4 h-2 text-[1em]  mt-5 px-14 py-5 md:w-5 md:h-3 md:mt-5 md:px-16 md:py-6 '>Précédent</Button>
            </div>
          )}
          {current < steps.length - 1 ? (
            <>
            <Button type="primary"  className='  w-4 h-2 text-[1em]  mt-0 px-14 py-5 md:w-5 md:h-3 md:mt-5 md:px-16 md:py-6  text-white' onClick={handleNext}>Suivant</Button></>
          ) : (
            <Button type="primary" className='  w-4 h-2 text-[1em]  mt-5 px-14 py-5 md:w-5 md:h-3 md:mt-5 md:px-16 md:py-6 ' htmlType="submit">Soumettre</Button>
          )}
        </div>
      </form>
 </div>
) : 
(
  // New form for "Personne"
  <div className="max-w-4xl mx-auto p-6">
        <Alert
      message={<b>Alerte d'Information</b>}
      description={
        <div>
           Veuillez noter que les fichiers doivent être soumis au format PDF et ne doivent pas excéder <b>2 Mo</b> par fichier,
           avec une taille totale maximale de <b>8 Mo</b> pour l'ensemble des fichiers.
          <p>Pour les champs obligatoires, veuillez sélectionner l'une des options suivantes :</p>
          <ul>
            <li> - Certificat de scolarité (pour les étudiants).</li>
            <li> - Copie certifiée conforme à l’original du diplôme (pour les diplômés de moins d’un an).</li>
          </ul>
        </div>
      }
      type="info"
      showIcon
    />
  <form onSubmit={handleSubmit(onSubmit)}>
    {/* New fields for personne */}

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">

            {/* Administrative dossier*/}
            {[
              { name: 'nomPrenom', label: 'Nom et Prénom  ', placeholder: "Nom et Prénom" },
              { name: 'cin', label: 'CIN', placeholder: "CIN" },
            ].map((field) => (
              <div key={field.name}>
                <label className="block text-sm font-medium">{field.label} <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span>&nbsp;</label>
                <input
                  className={`w-full p-3 sm:px-3 lg:px-4 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 ${errors[field.name] ? 'border-red-500' : ''}`}
                  placeholder={field.placeholder}
                  {...register(field.name, { required: 'Ce champ est requis.' })}
                />
                {errors[field.name] && <p className="text-red-500">{errors[field.name].message}</p>}
              </div>
            ))}

            {/* File upload fields for administrative dossier */}
            {/* {personneFields.map((field) => (
<div key={field.name} className=''>
  <label className="block text-sm font-medium">{field.label} <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span></label>
  <input
    className={`w-full p-1 sm:px-1 lg:px-1 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0 ${fileErrors[field.name] ? 'border-red-500' : ''}`}
    type="file"
    onChange={(e) => handleFileChange(e, field.name)}
    key={`${field.name}-${formValues.files[field.name]?.name || 'empty'}`}
  />
  {formValues.files[field.name] && (
    <p className="text-green-600 text-sm mt-1">
      Fichier sélectionné: {formValues.files[field.name].name}
    </p>
  )}
  {fileErrors[field.name] && (
    <p className="text-red-500 text-sm mt-1">{fileErrors[field.name]}</p>
  )}

</div>

            ))} */}
              {personneFields.map((field) => (
  <div key={field.name} className=''>
    <label className="block text-sm font-medium">
      {field.label} 
      {field.required && <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span>}
      {!field.required && <span className='text-gray-500'> (Optionnel)</span>}
    </label>
    <input
      className={`w-full p-1 sm:px-1 lg:px-1 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent text-sm sm:text-base lg:text-lg text-gray-700 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0 ${fileErrors[field.name] ? 'border-red-500' : ''}`}
      type="file"
      onChange={(e) => handleFileChange(e, field.name)}
      key={`${field.name}-${formValues.files[field.name]?.name || 'empty'}`}
    />
    {formValues.files[field.name] && (
      <p className="text-green-600 text-sm mt-1">
        Fichier sélectionné: {formValues.files[field.name].name}
      </p>
    )}
    {fileErrors[field.name] && (
      <p className="text-red-500 text-sm mt-1">{fileErrors[field.name]}</p>
    )}
  </div>
))}

          </div>
          <div className=' mt-8'>
          <p className="mt text-md text-gray-500 italic ">
  <span className='text-red-500 font-extrabold text-[10]'>&nbsp;*</span>&nbsp; signifie que tous les champs sont obligatoires.
</p>
          <Button type="primary" htmlType="submit" className='mt-2'>Soumettre</Button>
          </div>
  </form>

  </div>

)
}
    </div>
  );
};

export default DossierForm;

import React, { useEffect, useState } from "react";
import axios from "axios";
import StatsCard from "../Components/StatsCard";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { IoFolderOutline } from "react-icons/io5";
import { BsFileEarmarkText } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import CountUp from "react-countup";
// import UserTableAdmin from "../Components/UserTableAdmin";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";


const apiUrl = config.apiUrl

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const formatNumber = (num) => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
};

const Dashbord = () => {
  const [statistics, setStatistics] = useState({
    publications: 0,
    depots: 0,
    users: 0,
  });
  const [monthlyUsersData, setMonthlyUsersData] = useState([]);
  const [monthlyDepotsData, setMonthlyDepotsData] = useState([]);
  const [topFiveUsers, setTopFiveUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { userData ,token } = useAuth();

  const totalCount =
    statistics.publications + statistics.depots + statistics.users;

  // Calculate percentages and update labels
  const calculatePercentage = (value) => {
    if (totalCount === 0) return 0;
    return ((value / totalCount) * 100).toFixed(1);
  };

  // const doughnutChartData = {
  //   labels: [
  //     `Publications: ${calculatePercentage(statistics.publications)}%`,
  //     `Dépos: ${calculatePercentage(statistics.depots)}%`,
  //     `Utilisateurs: ${calculatePercentage(statistics.users)}%`,
  //   ],
  //   datasets: [
  //     {
  //       data: [
  //         calculatePercentage(statistics.publications),
  //         calculatePercentage(statistics.depots),
  //         calculatePercentage(statistics.users),
  //       ],
  //       backgroundColor: ["rgba(75,192,192,0.4)", "rgba(153,102,255,0.4)", "rgba(255,159,64,0.4)"],
  //       borderColor: ["rgba(75,192,192,1)", "rgba(153,102,255,1)", "rgba(255,159,64,1)"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const lineChartData = {
    labels: monthlyUsersData.map((data) => data.month),
    datasets: [
      {
        label: "Nombre d'utilisateurs mensuels",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: monthlyUsersData.map((data) => data.count),
      },
      {
        label: "Nombre de dépôts mensuels",
        backgroundColor: "#DCBBFC",
        borderColor: "#A854F8",
        data: monthlyDepotsData.map((data) => data.count),
      },
    ],
  };

  const topFiveUsersChartData = {
    labels: topFiveUsers.map((user) => user.nom),
    datasets: [
      {
        data: topFiveUsers.map((user) => user.depotCount),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/statistics`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Use token from context
            },
          }
        );
        setStatistics(response.data.data);
      } catch (err) {
        setError("Failed to fetch statistics");
      } finally {
        setLoading(false);
      }
    };

    const fetchMonthlyUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/users/monthly`
        );
        setMonthlyUsersData(response.data.data);
      } catch (err) {
        setError("Échec de la récupération des données mensuelles des utilisateurs.");
      } finally {
        setLoading(false);
      }
    };

    const fetchMonthlyDepots = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/depots/monthly`
        );
        setMonthlyDepotsData(response.data.data);
      } catch (err) {
        setError("Failed to fetch monthly depot data");
      } finally {
        setLoading(false);
      }
    };

    const fetchTopFiveUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/users/top-five`
        );
        setTopFiveUsers(response.data.data);
      } catch (err) {
        setError("Failed to fetch top five users");
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
    fetchMonthlyUsers();
    fetchMonthlyDepots();
    fetchTopFiveUsers();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/auth/users?page=${currentPage}&limit=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data, pagination } = response.data;
        setUsersData(data);
        setTotalPages(pagination.totalPages);
      } catch (err) {
        setError("Failed to fetch users");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const tableData = usersData.map((user) => [
    user.Nom || "N/A",
    user.Email || "N/A",
    user.roleId ? user.roleId.type : "N/A",
  ]);

  return (
    <div className="flex flex-row">
      <div className="flex flex-col gap-20 mx-auto">
        <div className="flex flex-col md:flex-row justify-center items-center mt-20 gap-20">
          {loading ? (
            <p className="text-center">Chargement...</p>
          ) : (
            <>
              <StatsCard
                title="Nombre de publication"
                info={
                  <CountUp
                    end={statistics.publications}
                    duration={2}
                    formatValue={(value) => formatNumber(value)}
                  />
                }
                bg="bg-blue-200"
                gradient="bg-blue-gradient"
                icon={<BsFileEarmarkText size={30} className="text-white" />}
              />
              <StatsCard
                title="Nombre des depots"
                info={
                  <CountUp
                    end={statistics.depots}
                    duration={2}
                    formatValue={(value) => formatNumber(value)}
                  />
                }
                bg="bg-red-200"
                gradient="bg-red-gradient"
                icon={<IoFolderOutline size={30} className="text-white" />}
              />
              <StatsCard
                title="Nombre des utilisateurs"
                info={
                  <CountUp
                    end={statistics.users}
                    duration={2}
                    formatValue={(value) => formatNumber(value)}
                  />
                }
                bg="bg-yellow-200"
                gradient="bg-yellow-gradient"
                icon={<FaRegUser size={30} className="text-white" />}
              />
            </>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full md:w-1/2 h-64">
            {monthlyUsersData.length > 0 ? (
              <Line
                data={lineChartData}
                options={chartOptions}
                key={JSON.stringify(lineChartData)}
              />
            ) : (
              <p>Aucune donnée disponible pour le graphique en courbes.</p>
            )}
          </div>
          {/* <div className="w-1/3 h-64">
            {totalCount > 0 ? (
              // <Doughnut data={doughnutChartData} options={chartOptions} />
            ) : (
              <p>No data available for the doughnut chart.</p>
            )}
          </div> */}
          <div className="w-full md:w-1/2 h-64">
            {topFiveUsers.length > 0 ? (
              <Doughnut
                data={topFiveUsersChartData}
                options={{
                  ...chartOptions,
                  plugins: {
                    legend: {
                      position: "right",
                    },
                    title: {
                      display: true,
                      text: "Top 5 des utilisateurs avec le plus de soumissions",
                    },
                  },
                }}
              />
            ) : (
              <p>No data available for the top five users chart.</p>
            )}
          </div>
        </div>
        {(userData?.role === "Admin")  && (
        <div className="overflow-x-auto">
          <div className="my-24">
            {message && <p className="text-green-500 text-center">{message}</p>}
            {error && <p className="text-red-500 text-center">{error}</p>}
            {loading ? (
              <p className="text-center">Chargement...</p>
            ) : (
              <>
                {/* <UserTableAdmin
                  headers={["Nom", "Email", "Rôle"]}
                  data={tableData}
                /> */}
                {/* <div className="flex justify-center mt-4">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-3 py-1  text-gray-200 rounded-lg mr-2 disabled:opacity-50"
                  >
                    <MdOutlineArrowBackIos />
                  </button>
                  <div className="px-3 py-1 mx-1 border border-blue-500 text-blue-500 rounded">
                    {currentPage}
                  </div>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1  text-gray-200 rounded-lg"
                  >
                    <MdOutlineArrowForwardIos />
                  </button>
                </div> */}
              </>
            )}
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Dashbord;

import React from "react";

const StatsCard = (props) => {
  const { title, info, gradient,icon } = props;

  return (
    <div className={`flex flex-col gap-5 ${gradient} h-fit w-fit p-5 rounded-md shadow-md`}>
      <h2 className="text-center text-xl text-gray-900">{title}</h2>
      <div className="flex flex-row justify-center items-end gap-x-4">
      {icon} 

      <p className="text-center font-extrabold text-xl">{info}</p>
      </div>
    </div>
  );
};

export default StatsCard;

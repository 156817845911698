import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useLogin from '../../AuthUser/hooks/AdminLogin';
//import { useAuth } from '../../AuthUser/context/AuthContent';
import LOGOLOGIN from "../../assets/logo-footer.png";

const Loginadmin  = () => {
    const { loading, error, loginAdmin} = useLogin();
    const [Email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();
  
    const handleInputChange = (e, fieldName) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
  
      const { value } = e.target;
      if (fieldName === 'Email') {
        setEmail(value);
      } else if (fieldName === 'password') {
        setPassword(value);
      }
    };
  
    const handleLogin = async (event) => {
      event.preventDefault();
  
      const response = await loginAdmin({ Email, password }, true); 
  
      if (response && response.fieldErrors) {
        // Affichez les erreurs spécifiques aux champs
        setValidationErrors(response.fieldErrors);
      }
    };
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
          <div className="w-full max-w-md">
            <div className="text-center mb-4">
              <img  src={LOGOLOGIN} alt="Logo" className="mx-auto h-20" />
              <h2 className="text-white text-3xl font-semibold ">E-dépot</h2>
            </div>
            <div className="bg-white shadow-md rounded-lg p-8">
              <h3 className="text-center text-xl font-semibold mb-6">Authentification</h3>
              <form onSubmit={handleLogin}>
                <div className="mb-4">
                  <label htmlFor="inputEmail" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                  <input
                    type="text"
                    id="inputEmail"
                    placeholder="name@example.com"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${validationErrors.Email ? 'border-red-500' : ''}`}
                    value={Email}
                    onChange={(e) => handleInputChange(e, 'Email')}
                  />
                  {validationErrors.Email && <p className="text-red-500 text-xs italic mt-2">{validationErrors.Email}</p>}
                </div>
                <div className="mb-4">
                  <label htmlFor="inputPassword" className="block text-gray-700 text-sm font-bold mb-2">Mot de passe</label>
                  <input
                    type="password"
                    id="inputPassword"
                    placeholder="Password"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${validationErrors.password ? 'border-red-500' : ''}`}
                    value={password}
                    onChange={(e) => handleInputChange(e, 'password')}
                  />
                  {validationErrors.password && <p className="text-red-500 text-xs italic mt-2">{validationErrors.password}</p>}
                </div>
                {validationErrors.general && (
                  <p className="text-red-500 text-xs italic mb-4">{validationErrors.general}</p>
                )}
                {error && (
                  <Alert
                    description={error}
                    type="error"
                    showIcon
                    closable
                    className="mb-4"
                  />
                )}
                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {loading ? <LoadingOutlined className="animate-spin" /> : 'Se connecter'}
                  </button>
                </div>
              </form>
            </div>
            <div className="text-center text-sm text-gray-600 mt-4">
              <a href="https://e-depot.snrt.ma/" className="hover:underline">e-depot.snrt.ma</a>
            </div>
          </div>
        </div>
      );
    };
    
    export default Loginadmin;

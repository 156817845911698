import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useSignup from "../../AuthUser/hooks/useSignup";

const Register = () => {
  const { loading, error, registerUser } = useSignup();
  const [formData, setFormData] = useState({
    Nom: "",
    nomSociete: "",
    Email: "",
    password: "",
    telephone: "",
    adresse: "",
    confirmPassword: "",
    userType: "",
    dataAgreement: true,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedType, setSelectedType] = useState("personne"); // State to manage selected type
  const navigate = useNavigate();

  const handleInputChange = (e, fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    setFormData({
      ...formData,
      [fieldName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    // Set the userType in formData before sending it to the backend
    const updatedFormData = { ...formData, userType: selectedType };

    const response = await registerUser(updatedFormData);

    if (response && response.fieldErrors) {
      setValidationErrors(response.fieldErrors);
    } else if (response.success) {
      navigate("/login");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <h2 className="text-2xl font-bold mb-6">Inscription</h2>
      <div className="flex flex-row items-center justify-center gap-5 p-4">
        <div
          className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-lg gap-3 text-white cursor-pointer w-52 ${
            selectedType === "personne" ? "bg-[#227f99]" : "bg-[#d3d3d3]"
          }`}
          onClick={() => setSelectedType("personne")}
        >
          Personne Physique
        </div>
        <div
          className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-xl gap-3 text-white cursor-pointer w-52 ${
            selectedType === "societe" ? "bg-[#63bbc9]" : "bg-[#d3d3d3]"
          }`}
          onClick={() => setSelectedType("societe")}
        >
          Societe
        </div>
      </div>
      <form
        className="w-full max-w-md bg-white p-8 shadow-md rounded-lg"
        onSubmit={handleRegister}
      >
        {selectedType === "personne" && (
          <>
            <label
              htmlFor="Nom"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Nom et Prenom
            </label>
            <input
              type="text"
              id="Nom"
              placeholder="Nom"
              className={`w-full p-2 border ${
                validationErrors.Nom ? "border-red-500" : "border-gray-300"
              } rounded-md mb-4`}
              value={formData.Nom}
              onChange={(e) => handleInputChange(e, "Nom")}
            />
            {validationErrors.Nom && (
              <p className="text-red-500 text-sm mb-2">
                {validationErrors.Nom}
              </p>
            )}
          </>
        )}
        {selectedType === "societe" && (
          <>
            <label
              htmlFor="nomSociete"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Nom de société
            </label>
            <input
              type="text"
              id="nomSociete"
              placeholder="Nom de société"
              className={`w-full p-2 border ${
                validationErrors.nomSociete
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md mb-4`}
              value={formData.nomSociete}
              onChange={(e) => handleInputChange(e, "nomSociete")}
            />
            {validationErrors.nomSociete && (
              <p className="text-red-500 text-sm mb-2">
                {validationErrors.nomSociete}
              </p>
            )}
            <label
              htmlFor="adresse"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Adresse :
            </label>
            <input
              type="text"
              id="adresse"
              placeholder="adresse"
              className={`w-full p-2 border ${
                validationErrors.adresse ? "border-red-500" : "border-gray-300"
              } rounded-md mb-4`}
              value={formData.adresse}
              onChange={(e) => handleInputChange(e, "adresse")}
            />
            {validationErrors.adresse && (
              <p className="text-red-500 text-sm mb-2">
                {validationErrors.adresse}
              </p>
            )}
          </>
        )}
        <label
          htmlFor="Email"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Email:
        </label>
        <input
          type="email"
          id="Email"
          placeholder="Email"
          className={`w-full p-2 border ${
            validationErrors.Email ? "border-red-500" : "border-gray-300"
          } rounded-md mb-4`}
          value={formData.Email}
          onChange={(e) => handleInputChange(e, "Email")}
        />
        {validationErrors.Email && (
          <p className="text-red-500 text-sm mb-2">{validationErrors.Email}</p>
        )}

        <label
          htmlFor="telephone"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Téléphone:
        </label>
        <input
          type="number"
          id="telephone"
          placeholder="telephone"
          className={`w-full p-2 border ${
            validationErrors.telephone ? "border-red-500" : "border-gray-300"
          } rounded-md mb-4`}
          value={formData.telephone}
          onChange={(e) => handleInputChange(e, "telephone")}
        />
        {validationErrors.telephone && (
          <p className="text-red-500 text-sm mb-2">
            {validationErrors.telephone}
          </p>
        )}

        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Mot de passe:
        </label>
        <input
          type="password"
          id="password"
          placeholder="********"
          className={`w-full p-2 border ${
            validationErrors.password ? "border-red-500" : "border-gray-300"
          } rounded-md mb-4`}
          value={formData.password}
          onChange={(e) => handleInputChange(e, "password")}
        />
        {validationErrors.password && (
          <p className="text-red-500 text-sm mb-2">
            {validationErrors.password}
          </p>
        )}

        <label
          htmlFor="confirmPassword"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Confirmez le mot de passe:
        </label>
        <input
          type="password"
          id="confirmPassword"
          placeholder="********"
          className={`w-full p-2 border ${
            validationErrors.confirmPassword
              ? "border-red-500"
              : "border-gray-300"
          } rounded-md mb-4`}
          value={formData.confirmPassword}
          onChange={(e) => handleInputChange(e, "confirmPassword")}
        />
        {validationErrors.confirmPassword && (
          <p className="text-red-500 text-sm mb-2">
            {validationErrors.confirmPassword}
          </p>
        )}
        {/* <label
          htmlFor="dataAgreement"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          <input
            type="checkbox"
            id="dataAgreement"
            className="mr-2"
            checked={formData.dataAgreement}
            onChange={(e) => handleInputChange(e, "dataAgreement")}
          />
          J'accepte les termes et conditions
        </label>
        {validationErrors.dataAgreement && (
          <p className="text-red-500 text-sm mb-2">
            {validationErrors.dataAgreement}
          </p>
        )} */}

        {error && (
          <Alert
            description={error}
            type="error"
            showIcon
            closable
            className="mb-4"
          />
        )}

        <button
          type="submit"
          className="w-full py-2 bg-[#1FACC7] text-white rounded-md hover:bg-[#1a9abf] focus:outline-none focus:ring-2 focus:ring-[#1FACC7] flex justify-center items-center"
        >
          {loading ? (
            <LoadingOutlined className="animate-spin text-2xl" />
          ) : (
            "Inscription"
          )}
        </button>
        <p className="mt-4 text-center text-sm text-gray-600">
          Déjà un compte ?{" "}
          <Link to="/login" className="text-[#1FACC7] hover:underline">
            Se connecter
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Register;

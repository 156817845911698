import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Table from "../Components/Table";
import config from "../../config";
import axios from "axios";
import { useAuth } from "../../AuthUser/context/AuthContent";
import { IoAddCircleOutline } from "react-icons/io5";

const apiUrl = config.apiUrl;

const FAQAdmin = () => {
  const [rawData, setRawData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token, userData } = useAuth();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentFAQ, setCurrentFAQ] = useState(null);

  const [form] = Form.useForm();

  // Function to fetch FAQ data
  const fetchFAQs = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/faq`);
      if (!response.ok) {
        console.error("Failed to fetch FAQ data");
        return;
      }

      const { data } = await response.json();
      setRawData(data);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  // Handle file viewing
  const viewFile = async (filePath) => {
    try {
      const url = `${apiUrl}/filesFaq/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };

  // Map data for the table
  const tableData = rawData.map((item) => [
    item.titre,
    item.fichier ? (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          viewFile(item.fichier);
        }}
        className="text-blue-600 hover:text-blue-800"
      >
        Télécharger
      </a>
    ) : (
      "Aucun fichier"
    ),
    item._id,
  ]);

  // Show modal
  const openModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  };

  // Handle modal submission
  const handleAddFAQ = async (values) => {
    const formData = new FormData();
    formData.append("titre", values.titre);
    if (values.fichier) {
      formData.append("fichier", values.fichier[0].originFileObj);
    }

    setLoading(true);
    try {
      await axios.post(`${apiUrl}/api/faq`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      fetchFAQs(); // Refresh table data
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding FAQ:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/faq/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success("FAQ supprimée avec succès");
      fetchFAQs(); // Refresh table data
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      message.error("Erreur lors de la suppression de la FAQ");
    }
  };

  const openEditModal = (faq) => {
    setCurrentFAQ(faq);
    form.setFieldsValue({
      titre: faq.titre,
      fichier: faq.fichier ? [{ name: faq.fichier, status: "done" }] : [],
    });
    setIsEditModalOpen(true);
  };

  const handleEditFAQ = async (values) => {
    const formData = new FormData();
    formData.append("titre", values.titre);
    if (values.fichier && values.fichier.length > 0) {
      formData.append("fichier", values.fichier[0].originFileObj);
    }

    setLoading(true);
    try {
      await axios.put(`${apiUrl}/api/faq/${currentFAQ._id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      fetchFAQs(); // Refresh table data
      setIsEditModalOpen(false);
      message.success("FAQ mise à jour avec succès");
    } catch (error) {
      console.error("Error editing FAQ:", error);
      message.error("Erreur lors de la mise à jour de la FAQ");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
       <h2 className="text-3xl mt-10 font-bold mb-6 text-center underline text-gray-800">
          FAQ
        </h2>
      <div className="flex md:flex-row flex-col justify-end md:items-center items-end gap-x-5 gap-y-2 mr-5 mt-10">
        <div>
          {(userData?.role === "Admin" || userData?.role === "Manager") && (
            <button
              onClick={openModal}
              className="flex flex-row justify-center items-center gap-x-3 bg-[#1FACC7] p-2 text-white rounded-lg hover:bg-[#116c7e] transition-all duration-500"
            >
              <IoAddCircleOutline />
              Ajouter une FAQ
            </button>
          )}
        </div>
      </div>
      <Table
        headers={["Titre", "Fichier"]}
        data={tableData}
        deleteButton={(id) => handleDelete(id)}
        editButton={(id) =>
          openEditModal(rawData.find((item) => item._id === id))
        }
      />

      {/* Add FAQ Modal */}
      <Modal
        title="Ajouter une FAQ"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Ajouter
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddFAQ}
          initialValues={{ titre: "" }}
        >
          <Form.Item
            label="Titre"
            name="titre"
            rules={[{ required: true, message: "Le titre est requis" }]}
          >
            <Input placeholder="Entrez le titre de la FAQ" />
          </Form.Item>
          <Form.Item
            label="Fichier"
            name="fichier"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
          >
            <Upload
              beforeUpload={() => false} // Prevent automatic upload
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Télécharger un fichier</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit FAQ Modal */}

      <Modal
        title="Modifier la FAQ"
        visible={isEditModalOpen}
        onCancel={() => setIsEditModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsEditModalOpen(false)}>
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Mettre à jour
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEditFAQ}
          initialValues={{ titre: "" }}
        >
          <Form.Item
            label="Titre"
            name="titre"
            rules={[{ required: true, message: "Le titre est requis" }]}
          >
            <Input placeholder="Entrez le titre de la FAQ" />
          </Form.Item>
          <Form.Item
            label="Fichier"
            name="fichier"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
          >
            <Upload
              beforeUpload={() => false} // Prevent automatic upload
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Télécharger un fichier</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FAQAdmin;

import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../AuthUser/context/AuthContent'; // Assurez-vous du bon chemin
import config from "../../config";
import { useNavigate } from 'react-router-dom';

const apiUrl = config.apiUrl;

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { token } = useAuth();
    const navigate  = useNavigate();

    const validatePassword = (password) => {
        if (password.length < 12) {
            return 'Le mot de passe doit contenir au moins 12 caractères.';
        }
        if (!/[A-Z]/.test(password)) {
            return 'Le mot de passe doit contenir au moins une lettre majuscule.';
        }
        if (!/[a-z]/.test(password)) {
            return 'Le mot de passe doit contenir au moins une lettre minuscule.';
        }
        if (!/[0-9]/.test(password)) {
            return 'Le mot de passe doit contenir au moins un chiffre.';
        }
        if (!/[@$!%*?&]/.test(password)) {
            return 'Le mot de passe doit contenir au moins un caractère spécial (@$!%*?&).';
        }
        return '';
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Les nouveaux mots de passe ne correspondent pas.');
            return;
        }

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        try {
            const response = await axios.post(
                `${apiUrl}/api/auth/change-password`,
                { currentPassword, newPassword },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Utilisez le token récupéré
                    },
                }
            );
            setSuccess(response.data.message);
            setError('');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            navigate('/')
        } catch (err) {
            console.error('Erreur lors du changement de mot de passe:', err);
            setError(err.response?.data?.message || 'Une erreur s\'est produite.');
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">Changer le mot de passe</h2>
            <form onSubmit={handleChangePassword} className="space-y-4">
                <div>
                    <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-600">Mot de passe actuel</label>
                    <input
                        type="password"
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="newPassword" className="block text-sm font-medium text-gray-600">Nouveau mot de passe</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">Confirmez le nouveau mot de passe</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
                {error && <p className="text-red-500 text-sm">{error}</p>}
                {success && <p className="text-green-500 text-sm">{success}</p>}
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Changer le mot de passe
                </button>
            </form>
        </div>
    );
};

export default ChangePassword;

import React, { useState } from "react";
import Logo from "../../assets/snrtlogo.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthUser/context/AuthContent";
import {
  FaHome,
  FaUserPlus,
  FaUsers,
  FaFileAlt,
  FaFolderOpen,
  FaSignOutAlt,
} from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
// import { FaCheckCircle } from 'react-icons/fa'
// import { PiListChecksFill } from "react-icons/pi";
// import { FaCheckDouble } from "react-icons/fa6";
import { FaCheckDouble } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseCircle } from "react-icons/io5";
import { MdChat } from "react-icons/md";

const SideBar = () => {
  const { userData, logout } = useAuth();
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle the sidebar
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <>
      <div className="flex xl:hidden justify-start items-start m-3">
        <GiHamburgerMenu onClick={toggleSidebar} className="cursor-pointer" />
      </div>

      <div
        className={`fixed xl:relative top-0 left-0 h-full xl:h-full flex flex-col justify-between border-e bg-gray-50 w-64  overflow-y-auto transition-transform ${
          isSidebarVisible ? "transform-none" : "transform -translate-x-full"
        } xl:transform-none`}
      >
        <div className="xl:hidden flex justify-end items-end p-3">
          <IoCloseCircle onClick={closeSidebar} className="cursor-pointer " />
        </div>
        <div className="px-4 py-6">
          <div className="flex ">
            <img
              src={Logo}
              className="ms-0 sm:max-w-34 md:min-w-36 w-28 xl:min-w-40 2xl:min-w-52"
              alt="srt logo"
            />
          </div>
          <h6 className="text-black my-3 mt-16">
            Bonjour, &nbsp; {userData?.nom}
          </h6>
          <hr className="w-full space-y-3" />
          <ul className="mt-6 space-y-1">
            <li>
              <Link
                to="/dashbord"
                className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
              >
                <FaHome /> <span>Acceuil</span>
              </Link>
            </li>
          </ul>
          <hr className="w-full my-5 flex justify-center items-center text-center" />

          <ul className="mt-6 space-y-5">
            {userData?.role === "Admin" && (
              <>
                <li>
                  <Link
                    to="/CreateUser"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaUserPlus /> <span>Créer un utilisateur</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/UserList"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaUsers className="mr-2" /> Gérer les utilisateurs
                  </Link>
                </li>

                <li>
                  <Link
                    to="/PublicationAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaFileAlt /> <span>Gérer Publications</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/SoumissionAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaFolderOpen /> <span>Gérer Depots</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="/ResultatAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaCheckDouble /> <span>Gérer Résultats</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/logs"
                    className=" flex gap-2 items-center rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaHistory /> <span>Historique</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faqAdmin"
                    className=" flex gap-2 items-center rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <MdChat /> <span>FAQ</span>
                  </Link>
                </li>
              </>
            )}
            {userData?.role === "Manager" && (
              <>
                <li>
                  <Link
                    to="/PublicationAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaFileAlt /> <span>Gérer les Publications</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/SoumissionAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaFolderOpen /> <span>Gérer les Depots</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ResultatAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaCheckDouble /> <span>Consulter les Résultats</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faqAdmin"
                    className=" flex gap-2 items-center rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <MdChat /> <span>FAQ</span>
                  </Link>
                </li>
              </>
            )}
            {userData?.role === "Consultant" && (
              <>
                <li>
                  <Link
                    to="/PublicationAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaFileAlt /> <span>Consulter les Publications</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/SoumissionAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaFolderOpen /> <span>Consulter les Soumissions</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ResultatAdmin"
                    className="flex items-center space-x-2 rounded-lg hover:text-black px-4 py-2 text-sm font-medium text-black"
                  >
                    <FaCheckDouble /> <span>Consulter les Résultats</span>
                  </Link>
                </li>
                
              </>
            )}
          </ul>
        </div>

        <div className="border-t border-gray-100 bg-gray-50 p-2">
          <form action="#">
            <button
              type="submit"
              onClick={handleLogout}
              className="group relative flex items-center space-x-2 justify-center w-full rounded-lg px-2 py-1.5 text-sm text-black hover:text-black"
            >
              <HiOutlineLogout className="size-5 opacity-75" />
              <span>Deconnexion</span>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default SideBar;

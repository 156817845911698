import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd"; // Import Ant Design's Pagination
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import UserTableAdmin from "../Components/UserTableAdmin";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";

const apiUrl = config.apiUrl;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const { token } = useAuth();
  const [selectedType, setSelectedType] = useState("personne");

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/auth/users?page=${currentPage}&limit=${pageSize}&userType=${selectedType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data, pagination } = response.data;
        setUsers(data);
        setTotalPages(pagination.totalPages);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setMessage("Session expirée. Veuillez vous reconnecter.");
          navigate("/login");
        } else {
          setError(
            err.response?.data?.message ||
              "Échec de la récupération des utilisateurs"
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate, token, currentPage, pageSize, selectedType]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEdit = (userId) => {
    navigate(`/edit-user/${userId}`);
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`${apiUrl}/api/auth/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(users.filter((user) => user._id !== userId));
      setMessage("Utilisateur supprimé avec succès.");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setMessage("Session expirée. Veuillez vous reconnecter.");
        navigate("/login");
      } else {
        setError(
          error.response?.data?.message ||
            "Erreur lors de la suppression de l'utilisateur."
        );
      }
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.Nom?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.nomSociete?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.Email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const headers = [
    selectedType === "personne" ? "Nom" : "Nom de Société",
    "Email",
    "Rôle",
    "Actions",
  ];

  const tableData = filteredUsers.map((user) => [
    selectedType === "personne" ? user.Nom || "N/A" : user.nomSociete || "N/A",
    user.Email || "N/A",
    user.roleId ? user.roleId.type : "N/A",
    <div key={user._id} className="flex justify-center items-center gap-3">
      <button
        onClick={() => handleEdit(user._id)}
        className="text-blue-500 hover:text-blue-700"
      >
        <MdOutlineEdit size={20} />
      </button>
      <button
        onClick={() => handleDelete(user._id.toString())}
        className="text-red-500 hover:text-red-700"
      >
        <MdOutlineDelete size={20} />
      </button>
    </div>,
  ]);

  return (
    <div className="my-24">
      <h2 className="text-2xl font-semibold text-gray-700 text-center">
        Gestion des Utilisateurs
      </h2>
      <div className="flex justify-end mr-20 mt-4">
        <div>
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-center gap-5 p-4">
        <div
          className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-lg gap-3 text-white cursor-pointer w-52 ${
            selectedType === "personne" ? "bg-[#227f99]" : "bg-[#d3d3d3]"
          }`}
          onClick={() => setSelectedType("personne")}
        >
          Personne Physique
        </div>
        <div
          className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-xl gap-3 text-white cursor-pointer w-52 ${
            selectedType === "societe" ? "bg-[#63bbc9]" : "bg-[#d3d3d3]"
          }`}
          onClick={() => setSelectedType("societe")}
        >
          Societe
        </div>
      </div>
      {message && <p className="text-green-500 text-center">{message}</p>}
      {error && <p className="text-red-500 text-center">{error}</p>}
      {loading ? (
        <p className="text-center">Chargement...</p>
      ) : (
        <>
          <UserTableAdmin headers={headers} data={tableData} />
          <Pagination
            align="center"
            current={currentPage}
            total={totalPages * 10}
            responsive
            pageSize={10}
            showSizeChanger={false}
            onChange={handlePageChange}
            className="p-5"
          />
        </>
      )}
    </div>
  );
};

export default UserList;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthUser/context/AuthContent'; // Assurez-vous que le chemin est correct
import config from "../../config";


const apiUrl = config.apiUrl

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useAuth(); // Obtenez le token du contexte d'authentification
  const [formData, setFormData] = useState({
    Nom: '',
    Email: '',
    roleId: '',
    password: '',
    confirmPassword: ''
  });
  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchUserAndRoles = async () => {
      try {
        const [userResponse, rolesResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/auth/users/${id}`, {
            headers: { Authorization: `Bearer ${token}` } // Ajoutez le token dans les en-têtes
          }),
          axios.get(`${apiUrl}/api/auth/roles`, {
            headers: { Authorization: `Bearer ${token}` } // Ajoutez le token dans les en-têtes
          })
        ]);

        const { Nom, Email, roleId } = userResponse.data.data;
        setFormData({ Nom, Email, roleId });
        setRoles(rolesResponse.data.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données de l\'utilisateur ou des rôles', error);
        setMessage('Erreur lors de la récupération des données.');
      }
    };

    fetchUserAndRoles();
  }, [id, token]); // Ajoutez le token comme dépendance

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (formData.password && formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Les mots de passe ne correspondent pas.';
    }

    if (!formData.Nom) {
      newErrors.Nom = 'Le nom est requis.';
    }

    if (!formData.Email) {
      newErrors.Email = 'L\'email est requis.';
    } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
      newErrors.Email = 'L\'email est invalide.';
    }

    if (!formData.roleId) {
      newErrors.roleId = 'Le rôle est requis.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const updatedData = { ...formData };
    if (!formData.password) {
      delete updatedData.password;
      delete updatedData.confirmPassword;
    }

    try {
      await axios.put(`${apiUrl}/api/auth/users/${id}`, updatedData, {
        headers: { 'Authorization': `Bearer ${token}` } // Ajoutez le token dans les en-têtes
      });
      setMessage('Utilisateur modifié avec succès.');
      navigate('/UserList');
    } catch (error) {
      console.error('Erreur lors de la modification de l\'utilisateur', error);
      setMessage('Erreur lors de la modification de l\'utilisateur.');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-5 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold text-gray-700 text-center">Modifier Utilisateur</h2>
      {message && <p className="text-green-500 text-center">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Nom</label>
          <input
            type="text"
            name="Nom"
            value={formData.Nom}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.Nom ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-500`}
          />
          {errors.Nom && <p className="text-red-500 text-xs">{errors.Nom}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.Email ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-500`}
          />
          {errors.Email && <p className="text-red-500 text-xs">{errors.Email}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Rôle</label>
          <select
            name="roleId"
            value={formData.roleId || ''}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.roleId ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-500`}
          >
            <option value="">Sélectionnez un rôle</option>
            {roles.map((role) => (
              <option key={role._id} value={role._id}>
                {role.type}
              </option>
            ))}
          </select>
          {errors.roleId && <p className="text-red-500 text-xs">{errors.roleId}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Mot de passe</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-500`}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Confirmer le mot de passe</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-500`}
          />
          {errors.confirmPassword && <p className="text-red-500 text-xs">{errors.confirmPassword}</p>}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300"
        >
          Modifier
        </button>
      </form>
    </div>
  );
};

export default EditUser;

// src/components/NotFound.js
import React from 'react';
import Error from "../../assets/error-404.svg";

const NotFound = () => {
  return (
    <div className="flex flex-col gap-y-10 p-20 items-center min-h-screen ">
      <h1 className="lg:text-6xl text-4xl font-bold text-gray-800">Page inéxistante</h1>
      <p className="text-xl text-gray-600">Désolé, mais la page que vous essayez d'afficher n'existe pas.</p>
      <img src={Error} alt="error" className='h-40 w-40' />
    </div>
  );
};

export default NotFound;

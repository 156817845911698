import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import aloula from "../../assets/channales/alaoula.svg";
import laayoun from "../../assets/channales/laayoune.svg";
import arriyadiya from "../../assets/channales/arriyadia.svg";
import attakafiya from "../../assets/channales/attakafiya.svg";
import almaghribiya from "../../assets/channales/almaghribia.svg";
import assadisa from "../../assets/channales/assadissa.svg";
import tamazight from "../../assets/channales/tamazight.svg";

const Footer = () => {
  return (
    <footer className="bg-gray-900  text-white pt-0 static top-0 left-0">
      <div className=" flex flex-col justify-center items-center space-y-0">
      <span className="mt-3">Notre Chaînes TV</span>

        <div className="  flex items-center w-[20em
        sm:w-[25em]  md:w-[35em]  xl:w-[45em]  2xl:w-[50em]        ">
          <Link to="https://snrtlive.ma/fr/al-aoula">
            <img src={aloula} alt="aloula " />
          </Link>
          <Link to="https://snrtlive.ma/fr/laayoune">
            <img src={laayoun} alt="laayoun " />
          </Link>{" "}
          <Link to="https://snrtlive.ma/fr/arryadia">
            <img src={arriyadiya} alt="arriyadiya " />
          </Link>{" "}
          <Link to="https://snrtlive.ma/fr/athaqafia">
            <img src={attakafiya} alt="atakafiya " />
          </Link>{" "}
          <Link to="https://snrtlive.ma/fr/almaghribia">
            <img src={almaghribiya} alt="almaghribiya" />
          </Link>{" "}
          <Link to="https://snrtlive.ma/fr/assadissa">
            <img src={assadisa} alt="assadissa " />
          </Link>
          <Link to="https://snrtlive.ma/fr/tamazight">
            <img src={tamazight} alt="tmazight" />
          </Link>
          {/* <img src={logo} alt="snrt " /> */}
        </div>
        <ul className="ms-4  flex flex-row space-x-4 py-3">
          <li className="hover:text-yellow-400 ">Contact</li>
          <li className="hover:text-yellow-400 ">Qui sommes-nous ?</li>
        </ul>
      </div>
      <div className="  h-1 flex flex-row items-center justify-center">
        <div className=" flex flex-row items-center justify-center">
          <hr className="" />
        </div>
      </div>
      <div className="flex items-center text-center  justify-center  mb-0 py-5 gap-2 text-gray-300">
        {/* <HiOutlineMail /> */}
        <p className="m-0">
          SNRT © 2024 · By R&D / PCR.DISI.R&D.24.005
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFileAlt } from 'react-icons/fa';
import { useAuth } from '../../AuthUser/context/AuthContent';
import config from "../../config";


const apiUrl = config.apiUrl

const Soumission = () => {
  const { userData, token } = useAuth();
  const [dossiers, setDossiers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchDossiers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/dossiers/user/${userData.id}`, {
          params: {
            search: searchTerm,
            page: page,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Fetched dossiers:", response.data.data); // Debug API response
        setDossiers(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
      } catch (error) {
        console.error('Error fetching dossiers:', error);
      }
    };
    if (userData.id) {
      fetchDossiers();
    }
  }, [userData.id, searchTerm, page]);
  

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const isValidDocumentKey = (key) => {
    return key !== "_id" && key !== "nomPrenom" && key !== "cin" && isNaN(key);
  };

  const viewBlob = async (filePath) => {
    try {
      const url = `${apiUrl}/api/soumission/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };

  return (
    <div className="">
      <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center text-gray-800">Mes dépôts</h2>
      <input
        type="text"
        placeholder="Rechercher par nom de publication..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border p-2 mb-4"
      />
      <div className="overflow-auto rounded-lg border border-gray-200 mx-20 my-10">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Publication</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Type</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Date de Dépôt</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Nom Prénom</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Documents Administratifs</th>
              {userData.type != "personne" &&(
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Documents Techniques</th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
  {dossiers.length === 0 ? (
    <tr>
      <td colSpan="7" className="py-4 px-6 text-center text-sm text-gray-700">
        Aucun dossier trouvé.
      </td>
    </tr>
  ) : (
    dossiers.map(dossier => (
      <tr key={dossier._id} className="hover:bg-gray-50 transition-all duration-200">
        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{dossier.publicationId?.PublicationName}</td>
        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{dossier.publicationId?.type}</td>
        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{new Date(dossier?.dateDepot).toLocaleDateString()}</td>
        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{dossier.dossierAdministratifId?.nomPrenom}</td>
        
        {/* Conditional display for 'personne' type */}
        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
          <div className="space-y-1">
            {userData.type === "personne" ? (
              <>
                {dossier.dossierAdministratifId?.cinCopie && (
                  <button
                    onClick={() => viewBlob(dossier.dossierAdministratifId?.cinCopie)}
                    className="flex items-center text-blue-600 hover:text-blue-800"
                  >
                    <FaFileAlt className="mr-2" /> Copie CIN
                  </button>
                )}
                {dossier.dossierAdministratifId?.scholarshipCertificat && (
                  <button
                    onClick={() => viewBlob(dossier.dossierAdministratifId?.scholarshipCertificat)}
                    className="flex items-center text-blue-600 hover:text-blue-800"
                  >
                    <FaFileAlt className="mr-2" /> Certificat Scolarité
                  </button>
                )}
                {dossier.dossierAdministratifId?.diplomMoinsUnAn && (
                  <button
                    onClick={() => viewBlob(dossier.dossierAdministratifId?.diplomMoinsUnAn)}
                    className="flex items-center text-blue-600 hover:text-blue-800"
                  >
                    <FaFileAlt className="mr-2" /> Diplôme Personne
                  </button>
                )}
              </>
            ) : (
              // For other types, display all document keys within dossierAdministratifId
              Object.entries(dossier.dossierAdministratifId).map(([key, value]) =>
                isValidDocumentKey(key) && key !== "userType" && key !== "cnss" && key !== "identifiantFiscal" && key !== "numeroRegistreCommerce" &&  value ? (
                  <button
                    key={key}
                    onClick={() => viewBlob(value)}
                    className="flex items-center text-blue-600 hover:text-blue-800"
                  >
                    <FaFileAlt className="mr-2" /> {key.replace(/([A-Z])/g, ' $1').trim()}
                  </button>
                ) : null
              )
            )}
          </div>
        </td>
        
        {/* Display dossier technique documents only if user type is not 'personne' */}
        {userData.type !== "personne" && (
          <td className="py-4 px-2 md:px-6 text-sm text-gray-700">
            <div className="space-y-1">
              {Object.entries(dossier.dossierTechniqueId).map(([key, value]) => 
                isValidDocumentKey(key) && value ? (
                  <button
                    key={key}
                    onClick={() => viewBlob(value)}
                    className="flex items-center text-blue-600 hover:text-blue-800"
                  >
                    <FaFileAlt className="mr-2" /> {key.replace(/([A-Z])/g, ' $1').trim()}
                  </button>
                ) : null
              )}
            </div>
          </td>
        )}
      </tr>
    ))
  )}
</tbody>

        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(page > 1 ? page - 1 : 1)}
          disabled={page === 1}
          className="px-4 py-2 mx-1 border rounded disabled:opacity-50"
        >
          Précédent
        </button>
        <button
          onClick={() => handlePageChange(page < totalPages ? page + 1 : totalPages)}
          disabled={page === totalPages}
          className="px-4 py-2 mx-1 border rounded disabled:opacity-50"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default Soumission;

import { Link } from "react-router-dom";
import SignUpBtn from "../Navabar/SignUpBtn";
import LoginBtn from "../Navabar/LoginBtn";
import Logo from "../../../assets/logosnrt.png";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useAuth } from "../../../AuthUser/context/AuthContent";
import { FaUserCircle } from "react-icons/fa"; // Icone du profil

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { userData, logout } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <>
      <div className="flex flex-col 2xl:text-2xl xl:text-xl lg:text-lg lg:flex-row justify-between gap-y-3 lg:gap-y-0 items-center bg-custom-radial py-2">
        <div className="flex justify-between w-full lg:w-auto">
          <div className="flex flex-row items-center gap-2 ml-7">
            <img src={Logo} className="w-20 m-3" alt="srt logo" />
            <div className="font-bold text-white text-2xl">E-Dépôt</div>
          </div>
          <button
            className="lg:hidden block text-white me-2"
            onClick={toggleMenu}
          >
            <GiHamburgerMenu size={24} />
          </button>
        </div>
        <div
          className={`lg:flex flex flex-col lg:flex-row lg:gap-5 2xl:gap-x-24 sm:gap-2 w-full lg:w-auto justify-center items-center lg:justify-end ${
            isOpen ? "block" : "hidden"
          } lg:block`}
        >
          <Link
            id="block1"
            to="/"
            className="block lg:inline-block text-white hover:text-black no-underline"
          >
            Accueil
          </Link>
          <Link
            id="block2"
            to="/Publication_Resultat?view=publication"
            className="block lg:inline-block text-white hover:text-black no-underline"
          >
            Téléchargements
          </Link>
          {userData && (
            <Link
              id="block3"
              to="/Soumission"
              className="block lg:inline-block text-white hover:text-black no-underline"
            >
              Mes dépôts
            </Link>
          )}
          <Link
            id="block4"
            to="/Publication_Resultat?view=resultat"
            className="block lg:inline-block text-white hover:text-black no-underline"
          >
            Résultats
          </Link>
          <Link
            id="block5"
            to="/faq"
            className="block lg:inline-block text-white hover:text-black no-underline"
          >
            FAQ
          </Link>
        </div>

        {userData ? (
          <div className="relative">
            <button
              onClick={toggleProfileMenu}
              className="flex items-center text-white hover:text-gray-300"
            >
              <FaUserCircle size={24} />
              {userData.type === "personne" && (
                <span className="ml-2">Bonjour, {userData.nom}</span>
              )}
              {userData.type === "societe" && (
                <span className="ml-2">Bonjour, {userData.nomSociete}</span>
              )}
            </button>
            {isProfileMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
                <Link
                  to="/changermotdepass"
                  className="block px-4 py-2 text-black hover:bg-gray-100"
                  onClick={() => setIsProfileMenuOpen(false)}
                >
                  Changer mon mot de passe
                </Link>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-black hover:bg-gray-100"
                >
                  Se déconnecter
                </button>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`mr-0 lg:mr-7 lg:flex lg:space-y-0 gap-y-5 md:gap-y-2 xl:space-y-0 2xl:space-y-0 sm:space-y-4 flex flex-col lg:flex-row justify-center items-center lg:gap-5  w-full lg:w-auto ${
              isOpen ? "block" : "hidden"
            } lg:block`}
          >
            <SignUpBtn />
            <LoginBtn />
          </div>
        )}
      </div>
      <div className="bg-gradient-to-b from-[#b4edf875] to-[#a7e0ea7b] py-5 ">
        <h1 className="pl-10 ms-4 font-semibold sm:text-2xl md:text-3xl xl:text-3xl 2xl:text-4xl">
          Dépôt en ligne des projets de production
        </h1>
      </div>
    </>
  );
};

export default Navbar;

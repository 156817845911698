import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthUser/context/AuthContent";
import { Modal, Pagination, Select } from "antd";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import moment from "moment";
import Swal from "sweetalert2";
import { Option } from "antd/es/mentions";
import Search from "../Components/Search";
import Table from "../Components/Table";
import config from "../../config";

const apiUrl = config.apiUrl;

const ResultatAdmin = () => {
  const [publicationId, setPublicationId] = useState("");
  const [name, setName] = useState("");
  const [document, setDocument] = useState(null);
  const [errors, setErrors] = useState({
    publicationId: "",
    name: "",
    document: "",
  });
  const [publications, setPublications] = useState([]);
  const [resultats, setResultats] = useState([]);
  const { userData, token } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedResultat, setSelectedResultat] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const pageSize = 10;

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/publications?limit=0`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const data = response.data.publications || [];
        setPublications(data);
      } catch (error) {
        console.error("Error fetching publications:", error);
      }
    };

    fetchPublications();
  }, [token, resultats]); // Re-fetch publications whenever `resultats` changes

  const viewFile = async (filePath) => {
    try {
      const url = `${apiUrl}/api/resultats/resultatFile/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };

  const fetchResultats = async (page, searchQuery = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/resultats?page=${page}&limit=${pageSize}&search=${encodeURIComponent(
          searchQuery
        )}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data.resultats || [];
      setResultats(data);
      setTotalPages(response.data.totalPages || 0);

      // Extract publication IDs from resultats
      const selectedPublicationIds = data.map(
        (resultat) => resultat.publicationId._id
      );
      setSelectedPublicationIds(selectedPublicationIds); // Add this state to track selected IDs
    } catch (error) {
      console.error("Error fetching resultats:", error);
    }
  };

  const [selectedPublicationIds, setSelectedPublicationIds] = useState([]);

  useEffect(() => {
    fetchResultats(currentPage, search);
  }, [currentPage, search]);

  const validateForm = () => {
    let validationErrors = {};

    if (!publicationId) {
      validationErrors.publicationId =
        "Le champ 'Publication ID' est obligatoire.";
    }

    if (!name) {
      validationErrors.name = "Le champ 'Nom' est obligatoire.";
    }

    if (!document) {
      validationErrors.document = "Le champ 'Document' est obligatoire.";
    } else {
      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(document.type)) {
        validationErrors.document = "Seuls les fichiers PDF sont autorisés.";
      }

      const maxSize = 2 * 1024 * 1024;
      if (document.size > maxSize) {
        validationErrors.document = "La taille du fichier ne doit pas dépasser 2MB.";
      }
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) return;

    const { isConfirmed } = await Swal.fire({
      title: "Es-tu sûr?",
      text: "Voulez-vous ajouter ce résultat ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, ajoutez-le!",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#1FACC7",
      cancelButtonColor: "#FF0000",
      iconColor: "#1FACC7",
      customClass: {
        popup: "w-80 max-w-4xl",
      },
    });

    if (!isConfirmed) return;

    const formData = new FormData();
    formData.append("publicationId", publicationId);
    formData.append("userId", userData.id);
    formData.append("name", name);
    formData.append("document", document);

    try {
      const response = await axios.post(
        `${apiUrl}/api/resultats`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Reset form fields
      setPublicationId("");
      setName("");
      setDocument(null);

      // Close the modal
      setIsModalVisible(false);

      // Immediately update resultats state
      setResultats((prevResultats) => [response.data, ...prevResultats]);

      Swal.fire({
        icon: "success",
        title: "Résultat ajoutée",
        text: "Le résultat a été ajoutée avec succès",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de l'ajout du résultat. Veuillez réessayer ultérieurement.",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
      console.error("Error creating Resultat:", error);
    }
  };

  const handleEditClick = (resultatId) => {
    const result = resultats.find((r) => r._id === resultatId);
    if (result) {
      setPublicationId(result.publicationId._id); // Ensure publicationId is set as a string
      setName(result.name);
      setSelectedResultat(result);
      setIsUpdateModalVisible(true);
    }
    console.log("selectedResultat", selectedResultat);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const { isConfirmed } = await Swal.fire({
      title: "Es-tu sûr?",
      text: "Voulez-vous mettre à jour ce résultat ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, mettez à jour!",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#1FACC7",
      cancelButtonColor: "#FF0000",
      iconColor: "#1FACC7",
      customClass: {
        popup: "w-80 max-w-4xl",
      },
    });

    if (!isConfirmed) {
      return; // User canceled the operation
    }

    const formData = new FormData();
    formData.append("publicationId", publicationId);
    formData.append("name", name);
    if (document) formData.append("document", document);

    try {
      const response = await axios.put(
        `${apiUrl}/api/resultats/${selectedResultat._id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("selectedResultat", selectedResultat._id);

      // Update the specific item in the state
      setResultats((prevResultats) =>
        prevResultats.map((resultat) =>
          resultat._id === selectedResultat._id
            ? { ...resultat, ...response.data }
            : resultat
        )
      );

      // Reset form fields
      setPublicationId("");
      setName("");
      setDocument(null);
      setSelectedResultat(null);

      // Close the modal
      setIsUpdateModalVisible(false);

      Swal.fire({
        icon: "success",
        title: "Résultat mis à jour",
        text: "Le résultat a été mis à jour avec succès",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de la mise à jour du résultat. Veuillez réessayer ultérieurement.",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
      console.error("Error updating Resultat:", error);
    }
  };

  const openModal = () => setIsModalVisible(true);

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsUpdateModalVisible(false);
    setPublicationId("");
    setName("");
    setDocument(null);
    setSelectedResultat(null);
  };

  const handleDelete = async (resultatId) => {
    const { isConfirmed } = await Swal.fire({
      title: "Es-tu sûr?",
      text: "Voulez-vous supprimer ce résultat ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimez-le!",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#FF0000",
      cancelButtonColor: "#1FACC7",
      iconColor: "#FF0000",
      customClass: {
        popup: "w-80 max-w-4xl",
      },
    });

    if (!isConfirmed) {
      return; // User canceled the operation
    }

    try {
      await axios.delete(`${apiUrl}/api/resultats/${resultatId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token in the headers
        },
      });

      // Immediately remove the deleted item from the state
      setResultats((prevResultats) =>
        prevResultats.filter((resultat) => resultat._id !== resultatId)
      );

      Swal.fire({
        icon: "success",
        title: "Résultat supprimé",
        text: "Le résultat a été supprimé avec succès",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de la suppression du résultat. Veuillez réessayer ultérieurement.",
        confirmButtonColor: "#1FACC7",
        cancelButtonColor: "#FF0000",
        customClass: {
          popup: "w-80 max-w-4xl",
        },
      });
      console.error("Error deleting Resultat:", error);
    }
  };

  const tableData = Array.isArray(resultats)
    ? resultats.map((resultat) => {
        return [
          resultat.name || "N/A",
          resultat.publicationId.PublicationName,
          resultat.document ? (
            <>
              <a
                href="#"
                onClick={() => viewFile(resultat.document)}
                className="flex items-center text-blue-600 hover:text-blue-800 ml-4"
              >
                <FaFileAlt className="mr-2" /> Voir le résultat
              </a>
            </>
          ) : (
            "No document"
          ),
          moment(resultat.date).format("DD/MM/YYYY") || "N/A",
          resultat._id,
        ];
      })
    : [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm !== undefined) {
      setSearch(searchTerm);
      setCurrentPage(1); // Reset to the first page when searching
    } else {
      console.error("Search term is undefined.");
    }
  };

  return (
    <>
      <div>
        <h2 className="text-3xl mt-10 font-bold mb-6 text-center underline text-gray-800">
          Les résultats
        </h2>
        <div className="flex md:flex-row flex-col justify-end md:items-center items-end gap-x-5 gap-y-2 mr-5 mt-10">
          <div>
            {(userData?.role === "Admin" || userData?.role === "Manager") && (
              <button
                onClick={openModal}
                className="flex flex-row justify-center items-center gap-x-3 bg-[#1FACC7] p-2 text-white rounded-lg hover:bg-[#116c7e] transition-all duration-500"
              >
                <IoAddCircleOutline />
                Ajouter une resultats
              </button>
            )}
          </div>
          <Search onSearch={handleSearch} />
        </div>
      </div>

      <div>
        <Table
          headers={[
            "Titre de resultat",
            "Nom de Publications",
            "Document",
            "Date",
          ]}
          data={tableData}
          editButton={handleEditClick}
          deleteButton={handleDelete}
        />

        <Pagination
          align="center"
          current={currentPage}
          total={totalPages * pageSize}
          responsive
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={handlePageChange}
          className="p-5"
        />
      </div>

      <Modal
        title="Ajouter un résultat"
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Sauvegarder"
        cancelText="Annuler"
      >
        <div className="flex justify-between flex-row my-3">
          <div className="flex flex-col gap-y-4 mr-2 w-1/2">
            <label htmlFor="type">Titre de publication</label>
            <Select
              id="publicationId"
              value={publicationId}
              placeholder="Select a publication"
              onChange={(value) => setPublicationId(value)}
              required
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value="" disabled>
                Select a publication
              </Option>
              {publications.map((publication) => (
                <Option
                  key={publication.publication._id}
                  value={publication.publication._id}
                  disabled={selectedPublicationIds.includes(publication.publication._id)} // Disable if already selected
                >
                  {publication.publication.PublicationName}
                </Option>
              ))}
            </Select>

            {errors.publicationId && (
              <span className="text-red-500 text-sm">
                {errors.publicationId}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-y-4 mr-2 w-1/2">
            <label htmlFor="PublicationName">Titre</label>
            <input
              type="text"
              id="name"
              value={name}
              className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
              onChange={(e) => setName(e.target.value)}
              required
            />
            {errors.name && (
              <span className="text-red-500 text-sm">{errors.name}</span>
            )}
          </div>
        </div>
        <div className="flex justify-between flex-row my-3">
          <div className="flex flex-col gap-y-4 mr-2 w-1/2">
            <label htmlFor="document">Document</label>
            <input
              type="file"
              id="document"
              className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
              onChange={(e) => setDocument(e.target.files[0])}
              required
            />
            {errors.document && (
              <span className="text-red-500 text-sm">{errors.document}</span>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Modifier un résultat"
        visible={isUpdateModalVisible}
        onOk={handleUpdate}
        onCancel={handleCancel}
        okText="Sauvegarder"
        cancelText="Annuler"
      >
        <div className="flex justify-between flex-row my-3">
          <div className="flex flex-col gap-y-4 mr-2 w-1/2">
            <label htmlFor="type">Titre de publication:</label>
            <select
              id="publicationId"
              value={publicationId}
              className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
              onChange={(e) => setPublicationId(e.target.value)}
              required
            >
              <option value="" disabled>
                Select a publication
              </option>
              {publications.map((publication) => (
                <option
                  key={publication.publication._id}
                  value={publication.publication._id}
                >
                  {publication.publication.PublicationName}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-y-4 mr-2 w-1/2">
            <label htmlFor="PublicationName">Resultat Name</label>
            <input
              type="text"
              id="name"
              value={name}
              className="h-8 text-black text-sm font-light rounded-lg bg-gray-100 pl-3"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="flex justify-between flex-row my-3">
          <div className="flex flex-col gap-y-4 mr-2 w-1/2">
            <label htmlFor="document">Document</label>
            <input
              type="file"
              id="document"
              className="text-sm text-stone-500 file:bg-[#1FACC7] file:p-2 file:text-white file:rounded-lg file:border-0"
              onChange={(e) => setDocument(e.target.files[0])}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResultatAdmin;

import React from "react";
import { useNavigate } from "react-router-dom";

const ExpiredLink = () =>{
    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate('/login');
    };
    return(
        <div className="max-w-md mx-auto mt-20 py-20 bg-white p-6 rounded-lg shadow-md text-center">
        <h2 className="text-2xl font-semibold text-gray-700 mb-6">Session expirée</h2>
        <p className="text-gray-600 mb-6">Votre session a expiré. Veuillez réessayer de réinitialiser votre mot de passe.</p>
        <button 
            onClick={handleBackToLogin} 
            className="w-full bg-[#1FACC7] text-white py-2 rounded-md hover:bg-[#379db2] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#36a7bd]"
        >
            Retour à la page de connexion
        </button>
    </div>
    );
           
   

}

export default ExpiredLink;
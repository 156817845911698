import React from "react";
import BG from "../../assets/bg.png";
// import "../Descreption/Descreption.css";

const Descreption = () => {
  return (
    <div className="my-10 md:mr-10 p-10 bg-gray-50 rounded-lg">
      <h2 className="descreption  text-[3em] xl:text-3xl xl:text-center font-bold mb-4">
        Pourquoi un tel projet ?
      </h2>
      <ul
        className="list-disc mb-0 py-5 space-y-3 text-md"
      >
        <li>Pour l'égalité de l'accès</li>
        <li>Pour la transparence et la responsabilité</li>
        <li>Pour assurer la traçabilité</li>
        <li>Pour améliorer la communication</li>
        <li>Pour améliorer la qualité des programmes</li>
        <li>Pour renforcer la performance</li>
        <li>Pour une meilleure gestion</li>
      </ul>
    </div>
  );
};

export default Descreption;

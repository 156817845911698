import React, { useEffect, useState } from "react";
import TableUser from "../Components/TableUser";
import axios from "axios";
import moment from "moment";
import { FaFileAlt } from "react-icons/fa";
import { Pagination } from "antd";
import Search from "../../Admin/Components/Search";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";

const apiUrl = config.apiUrl;

const Resultat = () => {
  const [resultats, setResultats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const pageSize = 10;
  const { token } = useAuth();

  const fetchResultats = async (page, searchQuery = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/resultats?page=${page}&limit=${pageSize}&search=${encodeURIComponent(
          searchQuery
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Inclure le token dans les en-têtes
          },
        }
      );
      const data = response.data.resultats || [];
      setResultats(data);
      setTotalPages(response.data.totalPages || 0);
    } catch (error) {
      console.error("Error fetching resultats:", error);
    }
  };

  useEffect(() => {
    fetchResultats(currentPage, search);
  }, [currentPage, search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const downloadFile = async (filePath, fileName) => {
    try {
      const url = `${apiUrl}/api/resultats/resultatFile/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const viewFile = async (filePath) => {
    try {
      const url = `${apiUrl}/api/resultats/resultatFile/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };

  const tableData = Array.isArray(resultats)
    ? resultats.map((resultat) => {
        return [
          resultat.name || "N/A",
          resultat.publicationId.PublicationName,
          resultat.document ? (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  viewFile(resultat.document);
                }}
                className="text-blue-600 hover:text-blue-800"
              >
                <FaFileAlt className="mr-2" /> Visualiser le resultat
              </a>
            </>
          ) : (
            "No document"
          ),
          moment(resultat.date).format("DD/MM/YYYY") || "N/A",
          resultat._id,
        ];
      })
    : [];

  const handleSearch = (searchTerm) => {
    if (searchTerm !== undefined) {
      setSearch(searchTerm);
      setCurrentPage(1); // Reset to the first page when searching
    } else {
      console.error("Search term is undefined.");
    }
  };

  return (
    <>
      <div className="flex md:flex-row flex-col justify-between items-center  gap-x-5 gap-y-2 md:mx-20 mt-10">
        <div className="text-5xl font-bold underline">Résultats</div>
        <div className="mt-2">
          <Search onSearch={handleSearch} />
        </div>
      </div>
      <TableUser
        headers={[
          "Titre de resultat",
          "Nom de publication",
          "Document",
          "Date",
        ]}
        data={tableData}
      />

      <Pagination
        align="center"
        current={currentPage}
        total={totalPages * pageSize}
        responsive
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={handlePageChange}
        className="p-5"
      />
    </>
  );
};

export default Resultat;

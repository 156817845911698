import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Pagination } from "antd";
import Search from "../Components/Search";
import TableUser from "../../User/Components/TableUser";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";


const apiUrl = config.apiUrl

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { token } = useAuth();

  const fetchLogs = async (page = 1, searchTerm = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/logs?page=${page}&limit=10&search=${encodeURIComponent(
          searchTerm
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedData = response.data.data.map((item) => ({
        userId: item.userId,
        action: item.action,
        timestamp: item.timestamp,
      }));

      setLogs(fetchedData);
      setFilteredData(fetchedData);
      setTotalPages(response.data.totalPages); // Remove fallback value for consistency
      setCurrentPage(response.data.currentPage); // Remove fallback value for consistency
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    fetchLogs(currentPage);
  }, [currentPage]);

  const handleSearch = (searchTerm) => {
    setCurrentPage(1); // Reset to first page on new search
    fetchLogs(1, searchTerm);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const tableData = filteredData.map((item) => {
    const username = item.userId?.Nom || "Unknown User";
    const userRole = item.userId?.roleId?.type || "Unknown User";
    return [
      username,
      userRole,
      item.action,
      moment(item.timestamp).format("DD/MM/YYYY HH:mm:ss"),
      item.id,
    ];
  });

  return (
    <div>
      <h2 className="text-5xl font-bold underline text-center">Historique</h2>
      <div className="flex md:flex-row flex-col justify-end md:items-center items-end gap-x-5 gap-y-2 mr-5 mt-10">
        <Search onSearch={handleSearch} />
      </div>

      <TableUser
        headers={["Résponsable","Role", "Action", "Date/Temps"]}
        data={tableData}
      />

      <Pagination
        align="center"
        current={currentPage}
        total={totalPages * 10}
        pageSize={10}
        showSizeChanger={false}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Logs;

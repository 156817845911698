import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { useAuth } from '../../AuthUser/context/AuthContent';

const Table = ({ headers = [], data = [], editButton, deleteButton }) => {
    const { userData } = useAuth();
  return (
    <div className="overflow-auto rounded-lg border border-gray-200 2xl:mx-20 lg:mx-0 my-10 ">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className=" max-w-52  py-2 font-medium text-gray-900">
                {header}
              </th>
            ))}
             {(userData?.role === "Manager" || userData?.role === "Admin") && (editButton || deleteButton) && (
            <th className="whitespace-nowrap  py-2 font-medium text-gray-900">Actions</th>
        )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-center">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} >
              {row.slice(0, -1).map((cell, cellIndex) => (
                <td key={cellIndex} className="max-w-96 px-4 py-2 text-gray-700">
                  {cell}
                </td>
              ))}
               {(userData?.role === "Manager" || userData?.role === "Admin") && (editButton || deleteButton) && (
              <td className="flex flex-row justify-center items-center gap-x-5 px-4 py-2 text-gray-700">
                {editButton && (
                  <button
                    onClick={() => editButton(row[row.length - 1])}
                    className="flex items-center text-blue-500 hover:text-blue-700"
                  >
                    <MdOutlineEdit size={20} />
                  </button>
                )}
                {deleteButton && (
                  <button
                    onClick={() => deleteButton(row[row.length - 1])}
                    className="flex items-center text-red-500 hover:text-red-700"
                  >
                    <MdOutlineDelete size={20} />
                  </button>
                )}
              </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  editButton: PropTypes.func,
  deleteButton: PropTypes.func
};

export default Table;
import React, { createContext, useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom'; // import useNavigate

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const storedData = localStorage.getItem("user_data");
        if (storedData) {
            const { token, user } = JSON.parse(storedData);
            setToken(token);
            setUserData(user);
            setIsAuthenticated(true);
        }

        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    logout(); // Clear the authentication state
                    window.location.href = '/login'; // Redirect using window.location as a fallback
                }
                return Promise.reject(error);
            }
        );
        // Cleanup interceptor on unmount
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []); // Add navigate as a dependency

    const login = (newToken, newData) => {
        localStorage.setItem("user_data", JSON.stringify({ token: newToken, user: newData }));
        setToken(newToken);
        setUserData(newData);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem("user_data");
        setToken(null);
        setUserData(null);
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ token, isAuthenticated, userData, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);

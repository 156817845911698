import { message } from 'antd';
import { useAuth } from '../../AuthUser/context/AuthContent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from "../../config";


const apiUrl = config.apiUrl

const useSignup = () => {
    const { login } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const registerUser = async (values) => {
        if (values.password !== values.confirmPassword) {
            setError("Les mots de passe ne correspondent pas");
            return { success: false, fieldErrors: { confirmPassword: "Les mots de passe ne correspondent pas" } };
        }
        try {
            setError(null);
            setLoading(true);
            const res = await fetch(`${apiUrl}/api/auth/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });

            const data = await res.json();

            if (res.status === 201) {
                message.success(data.message);
                return { success: true };
            } else if (res.status === 400) {
                setError(data.message);
                return { success: false, fieldErrors: data.fieldErrors || {} };
            } else {
                setError("Une erreur s'est produite");
                return { success: false, fieldErrors: {} };
            }
        } catch (error) {
            message.error(error.message);
            return { success: false, fieldErrors: {} };
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, registerUser };
};

export default useSignup;

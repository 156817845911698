import React from "react";
import { Link } from "react-router-dom";

const LoginBtn = () => {
  return (
    <div>
      <Link
        id="loginBtn"
        to="/login"
        className=" lg:mt-0 text-white p-2 rounded-lg bg-[#1FACC7] hover:bg-transparent hover:text-[#1FACC7] border-2 border-[#1FACC7] hover:border-white no-underline transition-all duration-500  "
      >
        Se connecter
      </Link>
    </div>
  );
};

export default LoginBtn;

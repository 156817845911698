import React, { useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import config from "../../config";


const apiUrl = config.apiUrl

const API_BASE_URL = `${apiUrl}/api`;

// const forgotPassword = async (email) => {
//   try {
//     const url = `${API_BASE_URL}/auth/forgot-password`;
//     const response = await axios.post(url, { email });

//     if (response.data.Status === 'Success') {
//       // Email sent successfully
//       message.success('Un email de réinitialisation a été envoyé.', 5);
//     } else {
//       // Error sending email
//       message.error(response.data.Status || 'Une erreur est survenue lors de l\'envoi de l\'email de réinitialisation.', 5);
//     }
//   } catch (error) {
//     // Handle the error here
//     message.error('Une erreur est survenue lors de la demande de réinitialisation de mot de passe.', 5);
//   }
// };
const forgotPassword = async (email) => {
  try {
    // Vérifier la présence de caractères invalides
    const invalidCharsRegex = /[<>{}()[\]\\^`~%]/;
    if (invalidCharsRegex.test(email)) {
      throw new Error('Caractères invalides');
    }

    // Nettoyer et valider l'email
    const sanitizedEmail = email.trim().toLowerCase();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(sanitizedEmail)) {
      throw new Error('Email invalide');
    }

    const url = `${API_BASE_URL}/auth/forgot-password`;
    const response = await axios.post(url, { email: sanitizedEmail });

    if (response.data.Status === 'Success') {
      // Email envoyé avec succès
      message.success('Un email de réinitialisation a été envoyé.', 5);
    } else {
      // Erreur lors de l'envoi de l'email
      message.error(response.data.Status || 'Une erreur est survenue lors de l\'envoi de l\'email de réinitialisation.', 5);
    }
  } catch (error) {
    // Gérer les erreurs
    if (error.message === 'Caractères invalides') {
      message.error('Caractères invalides dans l\'email', 5);
    } else if (error.message === 'Email invalide') {
      message.error('Email invalide', 5);
    } else {
      message.error(error.message || 'Une erreur est survenue lors de la demande de réinitialisation de mot de passe.', 5);
    }
  }
};



const PasswordRecovery = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await forgotPassword(email);
    } catch (error) {
      // No need to handle the error here since it's already handled in the forgotPassword function
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-center mb-6">
          Récupérer votre mot de passe
        </h2>
        <form onSubmit={handleSubmit}>
          <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">
            Email :
          </label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1FACC7] mb-4"
            required
          />
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#1FACC7] text-white py-2 rounded-md hover:bg-[#287b8b] focus:outline-none focus:bg-teal-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Veuillez patienter...' : 'Suivant'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordRecovery;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useLogin from '../../AuthUser/hooks/useLogin';

const Login = () => {
  const { loading, error, loginUser } = useLogin();
  const [Email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e, fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    const { value } = e.target;
    if (fieldName === 'Email') {
      setEmail(value);
    } else if (fieldName === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    const response = await loginUser({ Email, password }, true); 

    if (response && response.fieldErrors) {
      // Affichez les erreurs spécifiques aux champs
      setValidationErrors(response.fieldErrors);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center h-screen p-4'>
      <h2 className='text-2xl font-bold mb-6'>Connexion</h2>
      <form className='w-full max-w-md bg-white p-8 shadow-md rounded-lg' onSubmit={handleLogin}>
        <label htmlFor='Email' className='block text-sm font-medium text-gray-700 mb-2'>Email:</label>
        <input
          type='email'
          id='Email'
          placeholder='Email'
          className={`w-full p-2 border ${validationErrors.Email ? 'border-red-500' : 'border-gray-300'} rounded-md mb-4`}
          value={Email}
          onChange={(e) => handleInputChange(e, 'Email')}
        />
        {validationErrors.Email && <p className='text-red-500 text-sm mb-2'>{validationErrors.Email}</p>}

        <label htmlFor='password' className='block text-sm font-medium text-gray-700 mb-2'>Mot de passe:</label>
        <input
          type='password'
          id='password'
          placeholder='********'
          className={`w-full p-2 border ${validationErrors.password ? 'border-red-500' : 'border-gray-300'} rounded-md mb-4`}
          value={password}
          onChange={(e) => handleInputChange(e, 'password')}
        />
        {validationErrors.password && <p className='text-red-500 text-sm mb-2'>{validationErrors.password}</p>}

        {error && (
          <Alert
            description={error}
            type='error'
            showIcon
            closable
            className='mb-4'
          />
        )}

        <button type='submit' className='w-full py-2 bg-[#1FACC7] text-white rounded-md hover:bg-[#1a9abf] focus:outline-none focus:ring-2 focus:ring-[#1FACC7] flex justify-center items-center'>
          {loading ? <LoadingOutlined className='animate-spin text-2xl' /> : 'Connexion'}
        </button>
        <p className='mt-4 text-center text-sm text-gray-600'>
          Pas encore de compte ? <Link to="/register" className='text-[#1FACC7] hover:underline'>S'inscrire</Link>
        </p>
        <p className='mt-4 text-center text-sm text-gray-600'>
        <Link to="/password_recovery" className='text-[#1FACC7] hover:underline'> Mot de passe oublié ?</Link>
        </p>
      </form>
    </div>
  );
};

export default Login;

import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';

const TableUser = ({ headers = [], data = [], renderActions }) => {
  return (
    <div className="overflow-auto rounded-lg border border-gray-200 xl:mx-20 my-10">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="whitespace-nowrap  px-4 py-2 font-medium text-gray-900">
                {header}
              </th>
            ))}
            {renderActions && (
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Actions</th>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-100' : ''}>
              {row.slice(0, -1).map((cell, cellIndex) => (
                <td key={cellIndex} className="max-w-96 px-4 py-2 text-gray-700">
                  {cell}
                </td>
              ))}
              {renderActions && (
                <td className="flex flex-row justify-center items-center gap-x-5 px-4 py-2 text-gray-700">
                  {renderActions(row[row.length - 1])}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TableUser.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  renderActions: PropTypes.func, // Optional prop for rendering actions
};

export default TableUser;
import React from "react";
import { Link } from "react-router-dom";
const SignUpBtn = () => {
  return (
    <div>
      <Link
  to="/register"
  id="signUpBtn"
  className=" lg:mt-0 text-white p-2 rounded-lg  hover:bg-[#1FACC7]  border-2 border-white   hover:border-[#000000] no-underline transition-all duration-500  "
>
  S'inscrire
</Link>

    </div>
  );
};

export default SignUpBtn;

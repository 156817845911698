import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Pagination } from "antd";
import Search from "../../Admin/Components/Search";
import TableUser from "../Components/TableUser";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";
import { AiOutlineStop } from "react-icons/ai";
import { LuCalendarX2 } from "react-icons/lu";

const apiUrl = config.apiUrl;

const Publication = () => {
  const [publications, setPublications] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const { token, userData } = useAuth(); 
  const [submissions, setSubmissions] = useState([]);

  const fetchPublications = async (page = 1, searchTerm = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/publications?page=${page}&limit=10&search=${encodeURIComponent(
          searchTerm
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedData = response.data.publications.map((item) => ({
        publication: item.publication,
        fichiers: item.fichiers,
      }));
      setPublications(fetchedData);
      setFilteredData(fetchedData);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching publications:", error);
    }
  };

  const fetchSubmissions = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/dossiers/user/${userData.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubmissions(response.data);
      console.log("Fetched submissions:", response.data); // Debug: Log submissions data
    } catch (error) {
      console.error("Error fetching submissions:", error);
    }
  };

  useEffect(() => {
    fetchPublications(currentPage);
  }, [currentPage, token]);

  useEffect(() => {
    if (userData?.id) {
      fetchSubmissions();
    }
  }, [userData, token]);

  useEffect(() => {
    console.log("user ID for publication", userData?.id);
    console.log("Submissions data:", submissions);
  }, [submissions]);

  const isSubmissionDisabled = (publicationId) => {
    return Array.isArray(submissions.data) && 
      submissions.data.some((submission) => 
        submission.publicationId?._id === publicationId &&
        submission.userId === userData?.id
      );
  };

  const handleSearch = (searchTerm) => {
    fetchPublications(currentPage, searchTerm);
  };

  const downloadFile = async (filePath, fileName) => {
    try {
      const url = `${apiUrl}/files/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const viewFile = async (filePath, publicationId = null, fileName = null) => {
    // If there's no publicationId and fileName (for "programme" type files), skip logging
    if (!publicationId || !fileName) {
      console.log("No publicationId or fileName provided. Opening file directly for 'programme' type.");
  
      try {
        const url = `${apiUrl}/files/${filePath}`;
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(fileUrl, "_blank");
      } catch (error) {
        console.error("Error viewing file for 'programme' type:", error);
      }
      return;
    }
  
    // Log the file view to the backend for other publication types
    try {
      await axios.post(
        `${apiUrl}/log-file-view`,
        {
          publicationId,
          userId: userData.id,
          fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const url = `${apiUrl}/files/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };
  
  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const tableData = filteredData.map((item) => {
    const publication = item?.publication || {};
    const fichiers = item?.fichiers || {};
  
    return [
      publication.PublicationName || "N/A",
      publication.description || "N/A",
      publication.type || "N/A",
      publication.type === "offre" ? (
        publication.expirationDate ? moment(publication.expirationDate).format("DD/MM/YYYY HH:mm") : "N/A"
      ) : <LuCalendarX2 className="h-5 w-5" />,
      publication.type === "offre" && fichiers ? (
        <>
          {fichiers.avis && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (token) {
                  viewFile(fichiers.avis, publication._id, "Avis"); // Pass file name and publication ID
                } else {
                  navigate("/login");
                }
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              Visualiser l'Avis
            </a>
          )}
          <br />
          {fichiers.reglement && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (token) {
                  viewFile(fichiers.reglement, publication._id, 'Reglement'); // Pass file name and publication ID
                } else {
                  navigate("/login");
                }
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              Visualiser le Reglement
            </a>
          )}
          <br />
          {fichiers.CPS && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (token) {
                  viewFile(fichiers.CPS, publication._id, 'CPS'); // Pass file name and publication ID
                } else {
                  navigate("/login");
                }
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              Visualiser le CPS
            </a>
          )}
        </>
      ) : publication.type === "programme" && fichiers.programmeFichier ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            viewFile(fichiers.programmeFichier);
          }}
          className="text-blue-600 hover:text-blue-800"
        >
          Visualiser le fichier de Programme
        </a>
      ) : publication.type === "rectification" && fichiers.rectificationFichier ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            viewFile(fichiers.rectificationFichier);
          }}
          className="text-blue-600 hover:text-blue-800"
        >
          Visualiser le fichier de Rectification
        </a>
      ) : (
        "N/A"
      ),
      publication._id || "N/A",
    ];
  });
  
  const renderActionButtons = (publicationId, publication) => {
    return publication?.type === "offre" ? (
      <button
        onClick={() => handleSoumission(publicationId)}
        className={`flex items-center p-2 rounded-lg transition-all duration-500 ${
          isSubmissionDisabled(publicationId)
            ? "bg-gray-400 cursor-not-allowed"
            : "text-white bg-blue-500 hover:text-blue-500 hover:bg-white "
        }`}
        disabled={isSubmissionDisabled(publicationId)}
      >
        Soumission
      </button>
    ) : <AiOutlineStop />;
  };

  const handleSoumission = (publicationId) => {
    console.log("Publication ID:", publicationId);
    if (publicationId) {
      navigate("/dossier-submission", { state: { publicationId } });
    } else {
      console.error(
        "Publication ID is undefined. Cannot navigate to submission page."
      );
    }
  };

  return (
    <>
      <div className="flex md:flex-row flex-col justify-between items-center gap-x-5 gap-y-2 md:mx-20 mt-10">
        <div className="text-5xl font-bold underline">Publication</div>
        <div className="mt-2">
          <Search onSearch={handleSearch} />
        </div>
      </div>

      <TableUser
      headers={[
        "Publication Name",
        "Description",
        "Type",
        "Date d'expiration",
        "Fichier",
      ]}
      data={tableData}
      renderActions={(publicationId) =>
        renderActionButtons(
          publicationId,
          filteredData.find((item) => item.publication._id === publicationId)?.publication
        )
      }
    />

      <Pagination
        align="center"
        current={currentPage}
        total={totalPages * 10}
        responsive
        pageSize={10}
        showSizeChanger={false}
        onChange={handlePageChange}
        className="p-5"
      />
    </>
  );
};

export default Publication;

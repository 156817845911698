import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import config from "../../config";
import axios from "axios";
import { useAuth } from "../../AuthUser/context/AuthContent";
import { IoAddCircleOutline } from "react-icons/io5";
import Table from "../../Admin/Components/Table";

const apiUrl = config.apiUrl;

const FAQ = () => {
  const [rawData, setRawData] = useState([]);
  const { token, userData } = useAuth();


  // Function to fetch FAQ data
  const fetchFAQs = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/faq`);
      if (!response.ok) {
        console.error("Failed to fetch FAQ data");
        return;
      }

      const { data } = await response.json();
      setRawData(data);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  // Handle file viewing
  const viewFile = async (filePath) => {
    try {
      const url = `${apiUrl}/filesFaq/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };

  // Map data for the table
  const tableData = rawData.map((item) => [
    item.titre,
    item.fichier ? (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          viewFile(item.fichier);
        }}
        className="text-blue-600 hover:text-blue-800"
      >
        Télécharger
      </a>
    ) : (
      "Aucun fichier"
    ),
    item._id,
  ]);

 

  useEffect(() => {
    fetchFAQs();
  }, []);


 

  return (
    <>
       <h2 className="text-3xl mt-10 font-bold mb-6 text-center underline text-gray-800">
          FAQ
        </h2>
      <Table
        headers={["Titre", "Fichier"]}
        data={tableData}
      />
    </>
  );
};

export default FAQ;

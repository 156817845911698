import { message } from 'antd';
import { useAuth } from '../../AuthUser/context/AuthContent';
import { useState } from 'react';
import config from "../../config";


const apiUrl = config.apiUrl

const useLogin = () => {
    const { login, logout } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const loginUser = async (values, adminOnly = false) => {
        try {
            setError(null);
            setLoading(true);
    
            const res = await fetch(`${apiUrl}/api/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });
    
            const data = await res.json();
    
            if (res.status === 200) {
                if (adminOnly && data.user.role === 'Admin') {
                    logout();
                    setError("Accès refusé. Vous n'avez pas la permission d'accéder à cette interface.");
                    return;
                }
                message.success(data.message);
                login(data.token, data.user);
            } else if (res.status === 400 && data.fieldErrors) {
                // Return field errors to be handled in the form
                return { fieldErrors: data.fieldErrors };
            } else {
                setError(data.message || "Utilisateur ou mot de passe incorrect");
            }
    
        } catch (error) {
            setError("Une erreur s'est produite lors de la connexion");
        } finally {
            setLoading(false);
        }
        return null; // Assurez-vous de retourner une valeur
    };

    return { loading, error, loginUser };
};

export default useLogin;

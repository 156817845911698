import React from "react";
import { IoMdClose } from "react-icons/io";

const Modal = ({ isOpen, closeModal, onSubmit, buttonName, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="absolute bg-white text-black p-8 rounded-xl max-w-[600px] w-full flex flex-col shadow-2xl animate-fade-up animate-delay-300">
        <span className="flex justify-end">
          <IoMdClose
            className="text-white cursor-pointer h-6 w-6 border-2 border-red-500 bg-red-500 rounded-md"
            onClick={closeModal}
          />
        </span>
        <form onSubmit={onSubmit}>
          {children}
          <div className="flex justify-center mt-4">
            <button
              className="bg-[#1FACC7] text-white text-base font-bold p-2 rounded-lg"
              type="submit"
            >
              {buttonName}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthUser/context/AuthContent";
import config from "../../config";

const apiUrl = config.apiUrl;

const CreateUser = () => {
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState({
    Nom: "",
    nomSociete:"",
    Email: "",
    password: "",
    confirmPassword: "",
    roleId: "",
    adresse:"",
    telephone: "", // Ajouter le téléphone userType: '',
    userType: "", // Ajouter le type d'utilisateur
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { token } = useAuth();
  const [selectedType, setSelectedType] = useState("personne");

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/auth/roles`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoles(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles", error);
      }
    };

    fetchRoles();
  }, []);

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;
    return regex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value , userType: selectedType });

    if (value === "") {
      setErrors({ ...errors, [name]: "Ce champ est requis" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    if (name === "password" && !validatePassword(value)) {
      setErrors({
        ...errors,
        password:
          "Le mot de passe doit contenir au moins 12 caractères, dont des lettres majuscules, minuscules, des chiffres, et des caractères spéciaux.",
      });
    } else if (name === "confirmPassword" && value !== formData.password) {
      setErrors({
        ...errors,
        confirmPassword: "Les mots de passe ne correspondent pas.",
      });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let formValid = true;
    let newErrors = {};
  
    // Validate fields common to all users
    if (formData.Email === "") {
      formValid = false;
      newErrors.Email = "L'email est requis";
    }
    if (!validatePassword(formData.password)) {
      formValid = false;
      newErrors.password =
        "Le mot de passe doit contenir au moins 12 caractères, dont des lettres majuscules, minuscules, des chiffres, et des caractères spéciaux.";
    }
    if (formData.password !== formData.confirmPassword) {
      formValid = false;
      newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
    }
    if (formData.telephone === "") {
      formValid = false;
      newErrors.telephone = "Le téléphone est requis";
    }
    if (formData.roleId === "") {
      formValid = false;
      newErrors.roleId = "Veuillez sélectionner un rôle";
    }
  
    // Specific validation for "personne"
    if (selectedType === "personne" && formData.Nom.trim() === "") {
      formValid = false;
      newErrors.Nom = "Le nom est requis.";
    }
  
    // Specific validation for "societe"
    if (selectedType === "societe") {
      if (formData.nomSociete === "") {
        formValid = false;
        newErrors.nomSociete = "Le nom de la société est requis";
      }
      if (formData.adresse === "") {
        formValid = false;
        newErrors.adresse = "L'adresse est requise";
      }
    }
  
    if (!formValid) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const response = await axios.post(`${apiUrl}/api/auth/users`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      navigate("/UserList");
    } catch (error) {
      setMessage("Erreur lors de la création de l'utilisateur.");
    }
  };
  

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-5 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold text-gray-700 text-center">
        Créer un Utilisateur
      </h2>
      <div className="flex flex-row items-center justify-center gap-5 p-4">
        <div
          className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-lg gap-3 text-white cursor-pointer w-52 ${
            selectedType === "personne" ? "bg-[#227f99]" : "bg-[#d3d3d3]"
          }`}
          onClick={() => setSelectedType("personne")}
        >
          Personne Physique
        </div>
        <div
          className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-xl gap-3 text-white cursor-pointer w-52 ${
            selectedType === "societe" ? "bg-[#63bbc9]" : "bg-[#d3d3d3]"
          }`}
          onClick={() => setSelectedType("societe")}
        >
          Societe
        </div>
      </div>
      <form onSubmit={handleSubmit}>
      {selectedType === "personne" && (
        <div className="mb-4">
          <label className="block text-gray-700">Nom</label>
          <input
            type="text"
            name="Nom"
            value={formData.Nom}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.Nom ? "border-red-500" : ""
            }`}
          />
          {errors.Nom && <p className="text-red-500">{errors.Nom}</p>}
        </div>
      )}
      {selectedType === "societe" && (
        <div className="mb-4">
          <label className="block text-gray-700">Nom de société</label>
          <input
            type="text"
            name="nomSociete"
            value={formData.nomSociete}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.nomSociete ? "border-red-500" : ""
            }`}
          />
          {errors.nomSociete && <p className="text-red-500">{errors.nomSociete}</p>}
        </div>
      )}
      {selectedType === "societe" && (
        <div className="mb-4">
          <label className="block text-gray-700">Adresse</label>
          <input
            type="text"
            name="adresse"
            value={formData.adresse}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.adresse ? "border-red-500" : ""
            }`}
          />
          {errors.adresse && <p className="text-red-500">{errors.adresse}</p>}
        </div>
      )}
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.Email ? "border-red-500" : ""
            }`}
          />
          {errors.Email && <p className="text-red-500">{errors.Email}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Mot de passe</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.password ? "border-red-500" : ""
            }`}
          />
          {errors.password && <p className="text-red-500">{errors.password}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Confirmer le Mot de passe
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.confirmPassword ? "border-red-500" : ""
            }`}
          />
          {errors.confirmPassword && (
            <p className="text-red-500">{errors.confirmPassword}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Rôle</label>
          <select
            name="roleId"
            value={formData.roleId}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.roleId ? "border-red-500" : ""
            }`}
          >
            <option value="">Sélectionnez un rôle</option>
            {roles.map((role) => (
              <option key={role._id} value={role._id}>
                {role.type}
              </option>
            ))}
          </select>
          {errors.roleId && <p className="text-red-500">{errors.roleId}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Téléphone</label>
          <input
            type="text"
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              errors.telephone ? "border-red-500" : ""
            }`}
          />
          {errors.telephone && (
            <p className="text-red-500">{errors.telephone}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
        >
          Créer un utilisateur
        </button>
        {message && <p className="text-center text-red-500 mt-4">{message}</p>}
      </form>
    </div>
  );
};

export default CreateUser;
import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaDownload, FaFileAlt } from "react-icons/fa";
import { FaFileZipper } from "react-icons/fa6";
import { useAuth } from "../../AuthUser/context/AuthContent";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { Modal, Button, Form, Input, Upload, Pagination } from "antd";
import Search from "../Components/Search";
import Table from "../Components/Table";
import config from "../../config";
import { useParams } from "react-router-dom";

const apiUrl = config.apiUrl;

const DepotAdmin = () => {
  const { userData, token } = useAuth();
  const [dossiers, setDossiers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedType, setSelectedType] = useState("personne");
  const { id } = useParams(); // Get publicationId from the URL
  console.log("publicationId", id);

  const viewFile = async (filePath) => {
    try {
      const url = `${apiUrl}/api/soumission/${filePath}`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };
  const handleDownloadZip = async (id) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/downloads/download-complete-zip/${id}`,
        {
          responseType: "blob", // Important pour traiter la réponse comme un fichier binaire
          headers: {
            Authorization: `Bearer ${token}`, // Inclure le token dans les en-têtes
          },
        }
      );

      // Créer une URL pour le fichier et déclencher le téléchargement
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "complete_documents.zip"); // Nom du fichier
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading ZIP file:", error);
      alert("Erreur lors du téléchargement du fichier ZIP.");
    }
  };

  const handleDownloadZipAll = async (publicationId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/downloads/download-complete-zip-by-publication/${publicationId}`,
        {
          responseType: "blob", // Important for binary file handling
          headers: {
            Authorization: `Bearer ${token}`, // Include token in headers
          },
        }
      );

      // Create a URL for the file and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "complete_documents.zip"); // File name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading ZIP file:", error);
      alert("Erreur lors du téléchargement du fichier ZIP.");
    }
  };

  const dossiersPerPage = 10;

  const fetchDossiers = async (page = 1, searchTerm = "", id) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/dossiers/admin/depot-dossiers/${id}?page=${page}&limit=10&searchTerm=${searchTerm}&userType=${selectedType}`,
        // id is used as publicationId
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data, pagination } = response.data;

      if (data && Array.isArray(data)) {
        setDossiers(data);
        setFilteredData(data);
        setTotalPages(pagination.totalPages);
        setCurrentPage(pagination.page);
      }
    } catch (error) {
      console.error("Error fetching dossiers:", error);
    }
  };

  useEffect(() => {
    if (userData.id && id) {
      fetchDossiers(currentPage, "", id);
    }
  }, [userData.id, currentPage, id,selectedType]);

  const handleSearch = (searchTerm) => {
    fetchDossiers(currentPage, searchTerm);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const tableData = filteredData.map((dossier) => {
    const dossierAdmin = dossier?.dossierAdministratif || {};
    const dossierTech = dossier?.dossierTechnique || {};

    return [
      dossierAdmin?.nomPrenom || "----",
      dossierAdmin.declarationHonneur ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.declarationHonneur);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.declarationHonneur2 ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.declarationHonneur2);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.statutSociete ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.statutSociete);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.delegationPouvoir ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.delegationPouvoir);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.quitusFiscal ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.quitusFiscal);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.scholarshipCertificat ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.scholarshipCertificat);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.diplomMoinsUnAn ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.diplomMoinsUnAn);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.cinCopie ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.cinCopie);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.cnssMoinsUnAn ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.cnssMoinsUnAn);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.registreCommerce ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.registreCommerce);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierAdmin.agrementCCM ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierAdmin.agrementCCM);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierTech.moyensHumains ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierTech.moyensHumains);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierTech.moyensTechniques ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierTech.moyensTechniques);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      dossierTech.references ? (
        <div className="flex items-center justify-center space-x-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewFile(dossierTech.references);
            }}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaDownload className="" />
          </a>
        </div>
      ) : (
        "----"
      ),
      new Date(dossier?.dateDepot).toLocaleDateString() || "N/A",
      <FaFileZipper
        className="text-blue-600 hover:text-blue-800"
        onClick={() => handleDownloadZip(dossier._id)}
      />,
      dossier._id || "N/A",
    ];
  });

  const downloadExcelFile = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/dossiers/admin/exportDepotDossiers/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Convert response to blob
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));

      // Create a link to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "DepotDossiers.xlsx");
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the Excel file:", error);
    }
  };

  const downloadExcelFilePreview = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/dossiers/admin/exportFiles/${id}`, {
        responseType: "blob", // Set response type to blob to handle the file
      });
  
      // Create a download link and simulate a click to start the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PublicationDownloadLogs_${id}.xlsx`); // Name the file
      document.body.appendChild(link);
      link.click();
  
      // Clean up the link after download
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the Excel file:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col ">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 mt-10 underline">
          les dépôts des utilisateurs
        </h2>
        <div className="flex md:flex-row flex-col justify-end md:items-center items-end gap-x-5 gap-y-2 mr-5 mt-10">
          <button
            className="bg-[#1FACC7] text-white  font-semibold py-2 px-4 rounded shadow"
            onClick={() => handleDownloadZipAll(id)}
          >
            Télécharger Dossier Complete en ZIP
          </button>
          <button
            className="bg-[#1FACC7] text-white  font-semibold py-2 px-4 rounded shadow"
            onClick={downloadExcelFile}
          >
            Télécharger Fichier excel
          </button>
          <button
            className="bg-[#1FACC7] text-white  font-semibold py-2 px-4 rounded shadow"
            onClick={downloadExcelFilePreview}
          >
            Télécharger Fichier excel du téléchargement des fichier
          </button>
          <Search onSearch={handleSearch} />
        </div>
        <div className="flex flex-row items-center justify-center gap-5 p-4">
          <div
            className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-lg gap-3 text-white cursor-pointer w-52 ${
              selectedType === "personne" ? "bg-[#227f99]" : "bg-[#d3d3d3]"
            }`}
            onClick={() => setSelectedType("personne")}
          >
            Personne Physique
          </div>
          <div
            className={`py-1 px-3 rounded-lg transition-all duration-500 flex justify-center items-center text-xl gap-3 text-white cursor-pointer w-52 ${
              selectedType === "societe" ? "bg-[#63bbc9]" : "bg-[#d3d3d3]"
            }`}
            onClick={() => setSelectedType("societe")}
          >
            Societe
          </div>
        </div>
        <Table
          headers={[
            "Nom Prénom",
            "Déclaration sur l'honneur",
            "Déclaration sur l'honneur 2",
            "Statut de la société",
            "Délégation de pouvoir",
            "Quitus fiscal",
            "Certificat de scolarité",
            "Diplôme de la personne",
            "Copie CIN",
            "CNSS (moins d'un an)",
            "Registre de Commerce",
            "Agrément CCM",
            "Moyens Humains",
            "Moyens Techniques",
            "Références",
            "Date de Dépôt",
            "Actions",
          ]}
          data={tableData}
        />
        <Pagination
          align="center"
          current={currentPage}
          total={totalPages * 10}
          responsive
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePageChange}
          className="p-5"
        />
      </div>
    </>
  );
};

export default DepotAdmin;

import React, { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import config from "../../config";


const apiUrl = config.apiUrl

const API_BASE_URL = `${apiUrl}/api`;

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { id, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Vérification initiale du token au chargement de la page
    const verifyToken = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/verify-token/${id}/${token}`);
        if (response.data.Status !== "Valid") {
          navigate('/expired_link');
        }
      } catch (err) {
        navigate('/expired_link');
      }
    };
    verifyToken();
  }, [id, token, navigate]);

  const isPasswordValid = (password) => {
    const minLength = 12;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSymbols = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    
    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSymbols;
  };

  const handlePasswordChange = (e, setPassword) => {
    const value = e.target.value;
    setPassword(value);
    setError(''); // Réinitialiser l'erreur lors de la saisie
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Les nouveaux mots de passe ne correspondent pas.');
      return;
    }

    if (!isPasswordValid(newPassword)) {
      setError('Le mot de passe ne respecte pas les critères de sécurité.');
      return;
    }

    setIsLoading(true);
    
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/reset-password/${id}/${token}`, { password: newPassword });
      if (response.data.Status === "Success") {
        setSuccess('Mot de passe mis à jour avec succès');
        message.success('Mot de passe mis à jour avec succès.', 5);
        setError('');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        navigate('/expired_link');
      }
    } catch (err) {
      console.error('Erreur lors de la réinitialisation du mot de passe:', err);
      setError(err.response?.data?.message || 'Une erreur s\'est produite.');
      navigate('/expired_link');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h2 className="text-2xl font-bold mb-6">Nouveau mot de passe</h2>
      <form
        className="w-full max-w-md px-8 py-16 space-y-2 shadow-md rounded-lg"
        onSubmit={handleSubmit}
      >
        <label
          htmlFor="newPassword"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Nouveau mot de passe:
        </label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          required
          value={newPassword}
          onChange={(e) => handlePasswordChange(e, setNewPassword)}
          className={`w-full p-2 border ${
            error ? 'border-red-500' : 'border-gray-300'
          } rounded-md mb-4`}
        />

        <label
          htmlFor="confirmPassword"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Confirmer votre mot de passe:
        </label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          required
          value={confirmPassword}
          onChange={(e) => handlePasswordChange(e, setConfirmPassword)}
          className={`w-full p-2 border ${
            error ? 'border-red-500' : 'border-gray-300'
          } rounded-md mb-4`}
        />

        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

        <p className="text-sm text-gray-600 mb-4">
          Le mot de passe doit contenir au moins 12 caractères, incluant des majuscules, des minuscules, des chiffres et des symboles.
        </p>

        <button
          type="submit"
          className={`w-full py-2 bg-[#1FACC7] text-white rounded-md hover:bg-[#1a9abf] focus:outline-none focus:ring-2 focus:ring-[#1FACC7] flex justify-center items-center ${
            newPassword && confirmPassword && !error && !isLoading ? '' : 'bg-gray-400 cursor-not-allowed'
          }`}
          disabled={!newPassword || !confirmPassword || error || isLoading}
        >
          {isLoading ? (
            'Chargement...'
          ) : (
            <>
              <span className="mr-2">Valider</span>
              <FaArrowRight />
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default NewPassword;

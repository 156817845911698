import React, { useState } from "react";

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className="w-52">
      <input
        type="text"
        id="Search"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Rechercher..."
        className="w-full rounded-md border-[3px] border-gray-200 p-2.5 pe-10  sm:text-sm"
      />
    </div>
  );
};

export default Search;

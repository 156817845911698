import { useEffect } from 'react';
import { useAuth } from '../context/AuthContent'; // Adjust the path based on your project structure

const useAutoLogout = (timeout = 1800000 ) => {
  const { logout } = useAuth(); // Assuming you have a logout function in your auth context

  useEffect(() => {
    const timer = setTimeout(() => {
      logout();
      localStorage.removeItem('user_data'); // Assuming the token is stored under 'token'
    }, timeout);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [logout, timeout]);

  return null; // This hook doesn't render anything, just runs the effect
};

export default useAutoLogout;

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './User/Pages/Login';
import Register from './User/Pages/Register';
import PublicationAdmin from './Admin/Pages/PublicationAdmin';
import { useAuth } from './AuthUser/context/AuthContent';
import Home from './User/Pages/Home';
import Footer from './User/Components/Footer';
import Publication from './User/Pages/Publication';
import NotFound from './User/Components/NotFound';
import Dashbord from './Admin/Pages/Dashbord';
import SideBar from './Admin/Components/SideBar';
import Navbar from './User/Components/Navabar/Navbar';
import DossierForm from './User/Pages/DossierForm';
import UserList from './Admin/Pages/UserList';
import CreateUser from './Admin/Pages/CreateUser';
import EditUser from './Admin/Pages/EditUser';
import DepotAdmin from './Admin/Pages/DepotAdmin';
import Soumission from './User/Pages/Soumission';
import ResultatAdmin from './Admin/Pages/ResultatAdmin';
import Resultat from './User/Pages/Resultat';
import ChangePassword from './User/Components/ChangePassword';
import PasswordRecovery from './User/Pages/PasswordRecovery';
import NewPassword from './User/Pages/NewPassword';
import useAutoLogout from '../src/AuthUser/hooks/useAutoLogout'
import Logs from './Admin/Pages/Logs';
import Loginadmin from './Admin/Pages/Loginadmin';
import ExpiredLink from './User/Pages/ExpiredLink';
import EmailVerified from './User/Pages/email_verified';
import Publication_Resultat from './User/Pages/Publication_Resultat';
import DepotAdminList from './Admin/Pages/DepotAdminList';
import FAQAdmin from './Admin/Pages/FAQAdmin';
import FAQ from './User/Pages/FAQ';

const App = () => {
  const { isAuthenticated, userData } = useAuth();
   // Appelle la fonction de déconnexion automatique
  const UserLayout = ({ children }) => (
    <>
      <Navbar />
      <div className='min-h-screen'>
      {children}
      </div>
      <Footer />
    </>
  );

  const AdminLayout = ({ children }) => (
    <div className="flex flex-col xl:flex-row min-h-screen">
      <div className="lg:w-1/5 ">
        <SideBar />
      </div>
      <main className="flex-1 p-4">
        {children}
      </main>
    </div>
  );
  
  useAutoLogout();

  return (
    <Router>
      
      <Routes>
        {/* Authentication Routes */}
        <Route path="/register" element={!isAuthenticated ? <UserLayout><Register /></UserLayout> : (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager') ? <Navigate to="/dashbord" /> : <Navigate to="/" />} />
        <Route path="/login" element={!isAuthenticated ? <UserLayout><Login /></UserLayout> : (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager') ? <Navigate to="/dashbord" /> : <Navigate to="/" />} />

        {/* Redirect Admin, Consultant, and Manager from "/" to "/dashbord" */}
        <Route path="/" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <Navigate to="/dashbord" /> : <UserLayout><Home /></UserLayout>) : <UserLayout><Home /></UserLayout>} />
        <Route path="/dashboard/admin" element={!isAuthenticated ? <Loginadmin/> : (userData?.role === 'Admin' ? <Navigate to="/dashbord" /> : <Navigate to="/" />)} />

        {/* Redirect Users and Guests from "/dashbord" to "/" */}
        <Route path="/dashbord" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><Dashbord /></AdminLayout> : <Navigate to="/" />) : <Navigate to="/dashboard/admin" />} />

        {/* Not Found Route */}
        <Route path="*" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' ? <AdminLayout><NotFound /></AdminLayout> : <UserLayout><NotFound /></UserLayout>) : <UserLayout><NotFound /></UserLayout>} />
        
        {/* Password Reset Routes */}
        {/* <Route path="/forgot-password" element={<UserLayout><ForgotPassword /></UserLayout>} /> */}
        {/* <Route path="/reset_password/:id/:token" element={<UserLayout><ResetPassword /> </UserLayout>}></Route>
        <Route path="/resetpassworderror" element={<UserLayout><ResetPasswordError /> </UserLayout>} /> 
        <Route path="/email-verified/:id/:token" element={<UserLayout><EmailVerified/> </UserLayout>} />

        {/* User Routes */}
        <Route path="/Publication_Resultat" element={userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <Navigate to="/dashbord" /> : <UserLayout><Publication_Resultat /></UserLayout>} />
        <Route path="/faq" element={userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <Navigate to="/dashbord" /> : <UserLayout><FAQ/></UserLayout>} />
        <Route path="/dossier-submission" element={isAuthenticated ? <UserLayout><DossierForm /></UserLayout> : <Navigate to="/login" />} />
        <Route path="/Soumission" element={isAuthenticated ? <UserLayout><Soumission /></UserLayout> : <Navigate to="/login" />} />
        <Route path="/changermotdepass" element={isAuthenticated ? <UserLayout><ChangePassword /></UserLayout> : <Navigate to="/login" />} />

        {/* PassRecovery  */}    
        
        <Route path='/password_recovery' element={<UserLayout><PasswordRecovery/></UserLayout>}/>
        <Route path="/new_password/:id/:token" element={<UserLayout><NewPassword/></UserLayout>} />
        <Route path="/expired_link" element={<UserLayout><ExpiredLink /> </UserLayout>} />
        <Route path="/email-verified/:id/:token" element={<UserLayout><EmailVerified/> </UserLayout>} />

        {/* Admin Routes */}
        <Route path="/PublicationAdmin" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><PublicationAdmin /></AdminLayout> : <Navigate to="/Publication" />) : <Navigate to="/dashboard/admin" />} />
        <Route path="/UserList" element={isAuthenticated ? (userData?.role === 'Admin' ? <AdminLayout><UserList /></AdminLayout> : <Navigate to="/dashboard/admin" />) : <Navigate to="/dashboard/admin" />} />
        <Route path="/CreateUser" element={isAuthenticated ? (userData?.role === 'Admin' ? <AdminLayout><CreateUser /></AdminLayout> : <Navigate to="/dashboard/admin" />) : <Navigate to="/dashboard/admin" />} />
        <Route path="/edit-user/:id" element={isAuthenticated ? (userData?.role === 'Admin' ? <AdminLayout><EditUser /></AdminLayout> : <Navigate to="/dashboard/admin" />) : <Navigate to="/dashboard/admin" />} />
        <Route path="/SoumissionAdmin" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><DepotAdminList /></AdminLayout> : <Navigate to="/Soumission" />) : <Navigate to="/dashboard/admin" />} />    
        <Route path="/ResultatAdmin" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><ResultatAdmin /></AdminLayout> : <Navigate to="/Resultat" />) : <Navigate to="/dashboard/admin" />} />
        <Route path="/SoumissionAdmin/:id" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><DepotAdmin /></AdminLayout> : <Navigate to="/Resultat" />) : <Navigate to="/dashboard/admin" />} />
        <Route path="/logs" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><Logs /></AdminLayout> : <Navigate to="/" />) : <Navigate to="/dashboard/admin" />} />       
        <Route path="/faqAdmin" element={isAuthenticated ? (userData?.role === 'Admin' || userData?.role === 'Consultant' || userData?.role === 'Manager' ? <AdminLayout><FAQAdmin /></AdminLayout> : <Navigate to="/" />) : <Navigate to="/dashboard/admin" />} />       
     </Routes>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Publication from "./Publication";
import Resultat from "./Resultat";
import { FaFileArrowDown } from "react-icons/fa6";
import { IoMdPaper } from "react-icons/io";

const Publication_Resultat = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const initialView = queryParams.get("view") || "publication";
    const [showComponent, setShowComponent] = useState(initialView);

    useEffect(() => {
        setShowComponent(initialView);
    }, [initialView]);

    const handleButtonClick = (view) => {
        setShowComponent(view);
        navigate(`/Publication_Resultat?view=${view}`);
    };

    return (
        <>
            <div className="flex flex-col lg:flex-row justify-evenly mx-10">
                <div className=" my-20 flex flex-col gap-5 h-fit p-10 rounded-lg lg:w-1/4">
                    <button
                        className={`py-8 px-5 rounded-lg transition-all duration-500 flex justify-center items-center text-xl gap-3 text-white bg-[#227f99] hover:bg-[#d3d3d3]`}
                        onClick={() => handleButtonClick("resultat")}
                    >
                        <FaFileArrowDown className="h-8 w-8" /> Voir les résultats
                    </button>
                    <button
                        className={`py-8 px-5 rounded-lg transition-all duration-500 flex justify-center items-center text-xl gap-3 text-white bg-[#63bbc9] hover:bg-[#d3d3d3]`}
                        onClick={() => handleButtonClick("publication")}
                    >
                        <IoMdPaper className="h-8 w-8" /> Voir les publication
                    </button>
                </div>
                <div className="lg:w-3/4">
                    {showComponent === "publication" && <Publication />}
                    {showComponent === "resultat" && <Resultat />}
                </div>
            </div>
        </>
    );
};

export default Publication_Resultat;